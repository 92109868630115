import {ActionsUnion, DispatchAction} from "../type";
import {createAction} from "../action";
import {LanguageVariant, Movie} from "../../interfaces/MovieInterface";

export enum ActionTypes {
    SET_MOVIES = "setMovies",
    SET_FILTERED_MOVIES = "setFilteredMovies",
    SET_GRAMMAR_SKILL_VOCABULARY = "setGrammarSkillVocabulary"
}

export type ActionSetMoviesOptions = {
    movies: Movie[];
    languages: LanguageVariant[];
};

export type ActionSetFilteredMoviesOptions = {
    filteredMovies: Movie[];
};

export type ActionGrammarSkillVocabularyOptions = {
    grammarMap: Map<string, string[]>;
    skillMap: Map<string, string[]>;
    vocabularyMap: Map<string, string[]>;
};


export const Actions = {
    setMovies: (options: ActionSetMoviesOptions) => createAction(ActionTypes.SET_MOVIES, options),
    setFilteredMovies: (options: ActionSetFilteredMoviesOptions) => createAction(ActionTypes.SET_FILTERED_MOVIES, options),
    setGrammarSkillVocabulary: (options: ActionGrammarSkillVocabularyOptions) => createAction(ActionTypes.SET_GRAMMAR_SKILL_VOCABULARY, options),
};

export type Actions = ActionsUnion<typeof Actions>;

export function setMovies(options: ActionSetMoviesOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.setMovies(options));
    };
}

export function setFilteredMovies(options: ActionSetFilteredMoviesOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.setFilteredMovies(options));
    };
}

export function setGrammarSkillVocabulary(options: ActionGrammarSkillVocabularyOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.setGrammarSkillVocabulary(options));
    };
}