import {Actions} from "../type";
import {LanguageVariant, Movie} from "../../interfaces/MovieInterface";
import {ActionTypes} from "./action";

export type ReducerMovieListState = {
    filteredMovies: Movie[];
    movies: Movie[];
    skillMap: Map<string, string[]>;
    grammarMap: Map<string, string[]>;
    vocabularyMap: Map<string, string[]>;
    languages: LanguageVariant[];
};

const initialState: Readonly<ReducerMovieListState> = {
    movies: [],
    filteredMovies: [],
    skillMap: new Map(),
    grammarMap: new Map(),
    vocabularyMap: new Map(),
    languages: []
};

export function reducer(state: ReducerMovieListState = initialState, action: Actions): ReducerMovieListState {
    switch (action.type) {
        case ActionTypes.SET_MOVIES: {
            return {...state, ...action.payload};
        }
        case ActionTypes.SET_FILTERED_MOVIES: {
            return {...state, ...action.payload};
        }
        case ActionTypes.SET_GRAMMAR_SKILL_VOCABULARY: {
            return {...state, ...action.payload};
        }
        default: {
            return state;
        }
    }
}