import React from 'react';
import Input, {InputProps} from "./Input";

interface LabeledInputProps extends InputProps {
    label : string;
}

const LabeledInput : React.FunctionComponent<LabeledInputProps> = (props) => {
    const {name, type, label, errors, touched, br, autocomplete} = props;
    return (
        <>
            <div>
                <label htmlFor={name}>{label}</label>
                <Input name={name}
                       type={type}
                       errors={errors}
                       touched={touched}
                       autocomplete={autocomplete}
                       autocapitalize="none"
                />
            </div>
            { br && <br className="cl" /> }
        </>
    )
};

export default LabeledInput;