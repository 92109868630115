import React from 'react';
import {ActivityContent} from "../../../interfaces/MovieInterface";
import {getLessonPlanPath} from "../../../services/ContentResource";
import '../../../localization/i18n';
import { useTranslation } from 'react-i18next';

const REPORT_FOR_TEACHER = "teacher";
const REPORT_FOR_STUDENT = "student";

const REPORT_TYPE_PDF = "pdf";
const REPORT_TYPE_DOC = "doc";

interface ActivityContentProps {
    lessonId: number;
    activityContent : ActivityContent;
}

const ActivityContentHeader : React.FunctionComponent<ActivityContentProps> = (props) => {
    const {activityContent, lessonId} = props;
    const { t } = useTranslation();
    return ( activityContent &&
        <>
            <div className="lesson-download">
                <strong>{t('reportPlans')}</strong>
                <div className="doc">
                    <div className="item">
                        <a className="icon-pdf" href={getLessonPlanPath(REPORT_FOR_TEACHER, REPORT_TYPE_PDF, lessonId)} target="_blank" download/>
                        <span className="caption">{t('teacher')} <br/>PDF</span>
                    </div>
                    <div className="item">
                        <a className="icon-doc" href={getLessonPlanPath(REPORT_FOR_TEACHER, REPORT_TYPE_DOC, lessonId)} target="_blank" download/>
                        <span className="caption">{t('teacher')} <br/>DOC</span>
                    </div>
                    <div className="item">
                        <a className="icon-pdf" href={getLessonPlanPath(REPORT_FOR_STUDENT, REPORT_TYPE_PDF, lessonId)} target="_blank" download/>
                        <span className="caption">{t('student')} <br/>PDF</span>
                    </div>
                    <div className="item">
                        <a className="icon-doc" href={getLessonPlanPath(REPORT_FOR_STUDENT, REPORT_TYPE_DOC, lessonId)} target="_blank" download/>
                        <span className="caption">{t('student')} <br/>DOC</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ActivityContentHeader;
