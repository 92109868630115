import React, {useCallback, useContext, useEffect, useState} from 'react';
import Section from "../common/section/Section";
import MovieGrid from "./grid/MoviesGrid";
import MoviesFilter from "./filter/MoviesFilter";
import {MovieListFiltersContext, MovieListFiltersContextState} from "../../context/MovieListFiltersContext";
import {Difficulty, LanguageVariant, Lesson, Movie, OrderBy, OrderByDirection} from "../../interfaces/MovieInterface";
import {equalsIgnoreCaseAndDiacritics, isInStringArray} from "../../utils/StringUtils";
import * as _ from "lodash";
import {getLodashOrderSettings, mapToArray} from "../../utils/ArrayUtils";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../store/type";
import {setFilteredMovies} from "../../store/reducer-movie-list";
import '../../localization/i18n';
import { useTranslation } from 'react-i18next';
import { PAGE_MOVIES_LIST } from '../common/navigation/Navigation';

interface MovieListPageProps {
    movies : Movie[];
}

const MovieListPage: React.FunctionComponent<MovieListPageProps> = (props) => {
    const {movies} = props;

    let {state, dispatch} = useContext(MovieListFiltersContext);
    const dispatchRedux = useDispatch<Dispatch>();
    const { t } = useTranslation();

    // filtered movies grouped by language
    const [filteredMoviesGroupedByLanguage, setFilteredMoviesGroupedByLanguage] = useState<Map<string, Movie[]>>(applyFilters(movies, checkFilters, state, state.languageFilter));
    const setFilteredMoviesCallback = useCallback((fm) => dispatchRedux(setFilteredMovies({filteredMovies : fm})), [dispatch]);

    useEffect(() => {
        setFilteredMoviesCallback(mapToArray(filteredMoviesGroupedByLanguage));
    }, [filteredMoviesGroupedByLanguage]);

    useEffect(() => {
        dispatch({type: 'setPage',page: PAGE_MOVIES_LIST});
    }, [state.page]);


    useEffect(() => {
        setFilteredMoviesGroupedByLanguage(applyFilters(movies, checkFilters, state, state.languageFilter));
    }, [movies,
        state.difficultyFilter,
        state.languageFilter,
        state.searchFilter,
        state.orderBy]
    );

    return (
        <Section title={t('moviesList')} showSearch={true} iconCss="icon-movie">            
            <br className="cl"/>
            <MoviesFilter/>
            <MovieGrid filteredMovies={filteredMoviesGroupedByLanguage}/>
            <br className="cl"/>
            <p>&nbsp;</p>
        </Section>
    );
};

export function applyFilters(movies : Movie[], filterFunc: Function, state: MovieListFiltersContextState, languageFilter? : LanguageVariant): Map<string, Movie[]> {
    let filteredMoviesMap = new Map<string, Movie[]>();

    const {orderAttributes, orderDirections} = getLodashOrderSettings([
        {attribute: "moduleOrder", direction: OrderByDirection.ASC},
        {attribute: "code", direction: OrderByDirection.ASC}
    ]);

    // filter all movies according to : textSearch, language, difficulty, skills and grammars
    if (movies) {
        movies.forEach(movie => {
            if (filterFunc(movie, state)) {
                // group by languages
                if (!languageFilter || languageFilter.languageCode === movie.languageVariant.languageCode) {
                    // if languageFilter is not defined, use actual item/s language to add it to correct map value
                    const key = languageFilter ? languageFilter.languageCode : movie.languageVariant.languageCode;
                    let moviesForLanguage = filteredMoviesMap.get(key);
                    // first occurrence of language, create map entry with languageCode key
                    if (!moviesForLanguage) {
                        moviesForLanguage = new Array<Movie>();
                        filteredMoviesMap.set(key, moviesForLanguage);
                    }
                    moviesForLanguage.push(movie);
                }
            }
        });
    }

    return orderMovies(filteredMoviesMap, orderAttributes, orderDirections);
}

export function checkFilters(movie : Movie, state: MovieListFiltersContextState): Boolean {
    const {difficultyFilter, searchFilter} = state;

    const searchFilterActive = searchFilter && searchFilter.length > 0;

    // filter all movies according to : textSearch, language, difficulty
    if ((!difficultyFilter || difficultyFilter === movie.difficulty)
        && (!searchFilterActive || equalsIgnoreCaseAndDiacritics(movie.name, searchFilter))) {
        return true;
    }
    return false;
}

    


/**
 * Order movies in language groups.
 * @param filteredMoviesMap - movies to order (already grouped by languageCode in Map<languageCode:string, movies : Movie[]>
 * orderAttributes defines attributes of sorted object (in this case Movie) which should be applied during sorting
 * orderAttributes defines direction (asc, desc) of sorting for given attribute (according to attribute order defined in orderAttributes)
 */
function orderMovies(filteredMoviesMap: Map<string, Movie[]>, orderAttributes: any, orderDirections: any[]) {
    let orderedMoviesMap = new Map<string, Movie[]>();
    filteredMoviesMap.forEach((moviesForLanguage, languageCodeKey) => {
        orderedMoviesMap.set(languageCodeKey, _.orderBy(Array.from(moviesForLanguage), orderAttributes, orderDirections));
    });
    return orderedMoviesMap;
}

export default MovieListPage;
