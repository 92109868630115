import React, {useState} from 'react';

import PasswordRestore, {PasswordRestoreResultState} from "./PasswordRestore";
import {forgotPassword, RESPONSE_EMAIL_NOT_FOUND} from "../../../services/PublicResource";

const PasswordRestoreContainer: React.FunctionComponent<{}> = () => {

    const [resultStatus, setResultStatus] = useState<PasswordRestoreResultState>();

    function handlePasswordRestore(email : string) {
        forgotPassword(email).then(
            () => {
                setResultStatus(PasswordRestoreResultState.RESULT_SUCCESS);
            },
            (response) => {
                if (RESPONSE_EMAIL_NOT_FOUND === response) {
                    setResultStatus(PasswordRestoreResultState.RESULT_EMAIL_NOT_FOUND);
                } else {
                    setResultStatus(PasswordRestoreResultState.RESULT_FAILURE);
                }
            }
        )
    }

    return <PasswordRestore onPasswordRestore={handlePasswordRestore} resultStatus={resultStatus}/>
};


export default PasswordRestoreContainer;
