import React, {useCallback, useContext, useEffect, useState} from 'react';
import Section from "../common/section/Section";
import MovieGrid from "./grid/MoviesGrid";
import LinguisticPhenomenaFilter from "./filter/LinguisticPhenomenaFilter";
import {MovieListFiltersContext, MovieListFiltersContextState} from "../../context/MovieListFiltersContext";
import {Difficulty, LanguageVariant, Lesson, Movie, OrderBy, OrderByDirection} from "../../interfaces/MovieInterface";
import {equalsIgnoreCaseAndDiacritics, isInStringArray} from "../../utils/StringUtils";
import * as _ from "lodash";
import {applyFilters} from "./MovieListPage";
import {getLodashOrderSettings, mapToArray} from "../../utils/ArrayUtils";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../store/type";
import {setFilteredMovies} from "../../store/reducer-movie-list";
import '../../localization/i18n';
import { useTranslation } from 'react-i18next';
import {getSkillsGrammarsVocabularyForMovie} from "./detail/MovieDetail";
import {useLanguages} from "../../store/reducer-movie-list";
import { PAGE_LANG_FUNCTIONS } from '../common/navigation/Navigation';

interface MovieListPageProps {
    movies : Movie[];
}

const LinguisticPhenomenaPage: React.FunctionComponent<MovieListPageProps> = (props) => {
    const {movies} = props;

    let {state, dispatch} = useContext(MovieListFiltersContext);
    const displayMovies = state.skillFilter || state.grammarFilter || state.vocabularyFilter;
    const dispatchRedux = useDispatch<Dispatch>();
    const { t } = useTranslation();
    const languages = useLanguages();

    // filtered movies grouped by language
    const [filteredMoviesGroupedByLanguage, setFilteredMoviesGroupedByLanguage] = useState<Map<string, Movie[]>>(applyFilters(movies, checkFilters, state, state.languageLingusticFilter));
    const setFilteredMoviesCallback = useCallback((fm) => dispatchRedux(setFilteredMovies({filteredMovies : fm})), [dispatchRedux]);

    useEffect(() => {
        // set default selected language (to english)  
        if (!state.languageLingusticFilter) {            
             let selectedLanguage;
            for(let language of languages){
                selectedLanguage = language; 
                if (language.languageCode == "en" ) {
                    break;
                } 
              }
            dispatch({type: 'addLanguageLingusticFilter',language: selectedLanguage});
            console.log("!languageLingusticFilter");
        } else {
            console.log("languageLingusticFilter");
        }
    }, [state.languageLingusticFilter]);

    useEffect(() => {
        setFilteredMoviesCallback(mapToArray(filteredMoviesGroupedByLanguage));
    }, [filteredMoviesGroupedByLanguage]);

    useEffect(() => {
        dispatch({type: 'setPage',page: PAGE_LANG_FUNCTIONS});
    }, [state.page]);

    useEffect(() => {
        setFilteredMoviesGroupedByLanguage(applyFilters(movies, checkFilters, state, state.languageLingusticFilter));
    }, [movies,
        state.difficultyFilter,
        state.languageLingusticFilter,
        state.searchFilter,
        state.skillFilter,
        state.grammarFilter,
        state.vocabularyFilter,
        state.orderBy]
    );

    function checkFilters(movie : Movie, state: MovieListFiltersContextState): Boolean {
        const {difficultyFilter, searchFilter, skillFilter, grammarFilter, vocabularyFilter} = state;
        const searchFilterActive = searchFilter && searchFilter.length > 0;
    
        // filter all movies according to : textSearch, language, difficulty, skills and grammars
        const {skills, grammars, vocabulary} = skillsGrammarsVocabulary(movie.lessons);
        if ((!difficultyFilter || difficultyFilter === movie.difficulty)
            && (!searchFilterActive || (filterByNameSkillGrammarVocabulary(skills, grammars, vocabulary, searchFilter, movie.name)))
            && (!skillFilter || (skills.includes(skillFilter)))
            && (!grammarFilter || (grammars.includes(grammarFilter)))
            && (!vocabularyFilter || (vocabulary.includes(vocabularyFilter)))) {
            return true;
        }
        return false;
    }

    function filterByNameSkillGrammarVocabulary(skills: string[], grammars: string[], vocabulary: string[], searchFilter?: string, name?: string, ) {
        if (typeof searchFilter === 'string' && typeof name === 'string') {   
            if (equalsIgnoreCaseAndDiacritics(name, searchFilter) || isInStringArray(searchFilter, skills) || isInStringArray(searchFilter, grammars)|| isInStringArray(searchFilter, vocabulary)) {
                return true;
            }
            return false;
        }
        return true;
    }
    
    function skillsGrammarsVocabulary(lessons?: Lesson[]) {
        let skillMap = new Map();
        let grammarMap = new Map();
        let vocabularyMap = new Map();
        if (lessons) {
            getSkillsGrammarsVocabularyForMovie(lessons, skillMap, grammarMap, vocabularyMap);
        }
        return {
            skills: Array.from(skillMap.keys()),
            grammars: Array.from(grammarMap.keys()),
            vocabulary: Array.from(vocabularyMap.keys())
        }
    }
    

    return (
        <Section title={t('linguisticPhenomenons')}  showSearch={true} iconCss="icon-list">
            <LinguisticPhenomenaFilter/>
            {displayMovies && <MovieGrid filteredMovies={filteredMoviesGroupedByLanguage}/>}
            <br className="cl"/>
            <p>&nbsp;</p>
        </Section>
    );
};




export default LinguisticPhenomenaPage;
