import {Actions} from "../type";
import {ActionTypes} from "./action";
import {ActivityType} from "../../interfaces/MovieInterface";

export type ReducerActivityTypesState = {
    activityTypeMap: Map<number, ActivityType>
}

const initialState: Readonly<ReducerActivityTypesState> = {
    activityTypeMap: new Map<number, ActivityType>()
};

export function reducer(state: ReducerActivityTypesState = initialState, action: Actions): ReducerActivityTypesState {
    switch (action.type) {
        case ActionTypes.ACTIVITY_TYPES_LOADED: {
            const map = {activityTypeMap: action.payload.activityTypeMap}
            return map;
        }
        default: {
            return state;
        }
    }
}