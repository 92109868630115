import React, {useContext, useEffect, useState} from 'react';
import TagList from "./TagList";
import {ActivityContent, Lesson, Movie} from "../../../interfaces/MovieInterface";
import MovieData from "./MovieData";
import SubscriptionModal from "./SubscriptionModal";
import * as _ from "lodash";
import {MovieListFiltersContext} from "../../../context/MovieListFiltersContext";
import {compareArraysOfNumbers, copyMap, removeLetters} from "../../../utils/ArrayUtils";
import LessonDetail from "./LessonDetail";
import '../../../localization/i18n';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import imgWelcome from "../../../images/welcome.png";
import { PAGE_LANG_FUNCTIONS } from '../../common/navigation/Navigation';

interface MovieDetailProps {
    movie: Movie;
    useLinguisticPhenomena?: boolean;
}

const MovieDetail: React.FunctionComponent<MovieDetailProps> = (props) => {

    const {movie, useLinguisticPhenomena} = props;
    const {state} = useContext(MovieListFiltersContext);
    
    // if the detail is displayed from linguistic phenomena page, display  lessons filtered  
    // with tag selected there, otherwise display all
    const inheritedVocabularyFilter = state.page === PAGE_LANG_FUNCTIONS ? state.vocabularyFilter : undefined;
    const inheritedGrammarFilter = state.page === PAGE_LANG_FUNCTIONS ? state.grammarFilter : undefined;
    const inheritedSkillFilter = state.page === PAGE_LANG_FUNCTIONS ? state.skillFilter : undefined;

    const [vocabularyFilter, setVocabularyFilter] = useState<string | undefined>(inheritedVocabularyFilter);
    const [grammarFilter, setGrammarFilter] = useState<string | undefined>(inheritedGrammarFilter);
    const [skillFilter, setSkillFilter] = useState<string | undefined>(inheritedSkillFilter);
    
    const { t } = useTranslation();

    const [skillMap, setSkillMap] = useState<Map<string, string[]>>(new Map());
    const [grammarMap, setGrammarMap] = useState<Map<string, string[]>>(new Map());
    const [vocabularyMap, setVocabularyMap] = useState<Map<string, string[]>>(new Map());
    const [filteredLessons, setFilteredLessons] = useState<Lesson[]>();

    const [subscriptionModalVisible, setSubscriptionModalVisible] = useState(false);

    useEffect(() => {
        const sm = new Map();
        const gm = new Map();
        const vm = new Map();
        if (movie && movie.lessons) {
            getSkillsGrammarsVocabularyForMovie(movie.lessons, sm, gm, vm);
            setSkillMap(sm);
            setGrammarMap(gm);
            setVocabularyMap(vm);
        }
    }, [movie]);

    useEffect(() => {
        if (movie && movie.lessons) {
            setFilteredLessons(applyFilters(movie.lessons, grammarFilter, skillFilter, vocabularyFilter))
        }
    }, [movie, vocabularyFilter, grammarFilter, skillFilter]);

    const selectVocabulary = (vocabulary) => {
        setVocabularyFilter(vocabulary);
        setGrammarFilter(undefined);
        setSkillFilter(undefined);
    };

    const deselectVocabulary = () => {
        setVocabularyFilter(undefined);
    };

    const selectGrammar = (grammar) => {
        setGrammarFilter(grammar);
        setSkillFilter(undefined);
        setVocabularyFilter(undefined);
    };

    const deselectGrammar = () => {
        setGrammarFilter(undefined);
    };

    const selectSkill = (skill) => {
        setSkillFilter(skill);
        setVocabularyFilter(undefined);
        setGrammarFilter(undefined);
    };

    const deselectSkill = () => {
        setSkillFilter(undefined);
    };


    return (
        movie ?
            <>
                <SubscriptionModal 
                    visible={subscriptionModalVisible}
                    setVisible={setSubscriptionModalVisible} />
                <hr className="detail-top-hr"/>

                {/* Basic Movie Data - name, cover image, short description, movie statistics ... */}
                <MovieData movie={movie}/>

                <hr className="cl"/>

                {/* List of language skills for the movie. It is tag cloud, with multiple selection allowed */}
                <div className="filter-list">
                    <TagList
                        title={t('vocabulary')}
                        type="vocabulary col-3"
                        tags={vocabularyMap}
                        selectedTags={vocabularyFilter ? [vocabularyFilter] : []}
                        displayLessons={true}
                        onSelect={selectVocabulary}
                        onDeselect={deselectVocabulary}
                    />

                    {/* List of language skills for the movie. It is tag cloud, with multiple selection allowed */}
                    <TagList
                        title={t('langFunctions')}
                        type="skills col-3"
                        tags={skillMap}
                        selectedTags={skillFilter ? [skillFilter] : []}
                        displayLessons={true}
                        onSelect={selectSkill}
                        onDeselect={deselectSkill}
                    />

                    {/* List of grammar rules for the movie. It is tag cloud, with multiple selection allowed */}
                    <TagList
                        title={t('grammar')}
                        type="grammar col-3"
                        tags={grammarMap}
                        selectedTags={grammarFilter ? [grammarFilter] : []}
                        displayLessons={true}
                        onSelect={selectGrammar}
                        onDeselect={deselectGrammar}
                    />
                </div>

                <hr className="cl"/>

                <h2>{t('lessonList')}:</h2>

                {/* Only lessons which fulfill all filter criteria (GR, LS and VO) are displayed*/}
                <div className="lesson-list">
                    {filteredLessons && filteredLessons.map(lesson => {
                        let skillMapForLesson = new Map();
                        let grammarMapForLesson = new Map();
                        let vocabularyMapForLesson = new Map();

                        getSkillsGrammarsVocabularyForMovie(new Array(lesson), skillMapForLesson, grammarMapForLesson, vocabularyMapForLesson);

                        function acLoop(lesson: Lesson) {
                            return lesson.activityContents.map(activityContent =>
                                <LessonDetail
                                    key={lesson.id + activityContent.id}
                                    movie={movie}
                                    lesson={lesson}
                                    activityContent={activityContent}
                                    skillFilter={skillFilter}
                                    grammarFilter={grammarFilter}
                                    vocabularyFilter={vocabularyFilter}
                                    availableSkills={skillMapForLesson}
                                    availableGrammar={grammarMapForLesson}
                                    availableVocabulary={vocabularyMapForLesson}
                                    subscriptionModalShow={() => setSubscriptionModalVisible(true)}/>
                            )
                        }

                        return acLoop(lesson);

                    })}
                </div>
            </> : null
    );
};

/**
 * Calculate "Grammar Rules (GR)" , "Language Skills (LS)"  and "Vocabulary (VO)" for given movie.
 * Movie can be part of different lessons (which has GR, LS and VO assigned), and we need distinct list of GR, LS and VO.
 * Three maps are returned. One for GR, one for LS and one for VO.
 * key = GR(name) | LS(name) | VO(name), value = distinct array of lesson names, where it is included
 *
 * @param lessons
 */
export function getSkillsGrammarsVocabularyForMovie(lessons: Lesson[], skillMap: Map<string, string[]>, grammarMap: Map<string, string[]>, vocabularyMap: Map<string, string[]>): void {

    function orderLessons(map: Map<string, string[]>) {
        let sortedMap = new Map<string, string[]>();
        map.forEach((value, key) => {
            sortedMap.set(key, lexicographicSortStrings(value));
        });
        copyMap(sortedMap, map, keepOnlyFirstLetterAndNumber);
    }

    /**
     * Creates clone of array from param, modifying values this way : "Lesson 1" -> "L1"
     * @param lessons
     */
    function keepOnlyFirstLetterAndNumber(lessons : string[]) {
        return Array.from(lessons, lesson => {return lesson.length > 1 ? (lesson.charAt(0) + lesson.substr(lesson.indexOf(' ') + 1)) : ""})
    }

    function orderMap(map: Map<string, string[]>) {
        // sort array by values. Values are arrays with lesson numbers - we will compare them as arrays, see compareArraysOfNumbers method
        let sortedMap = new Map(Array.from(map).sort((a, b) => {
                const a1 = removeLetters(a[1]);
                const b1 = removeLetters(b[1]);
                return compareArraysOfNumbers(a1, b1);
            }
        ));
        copyMap(sortedMap, map);
    }

    if (lessons) {
        for (let lesson of lessons) {
            if (lesson.activityContents) {
                for (let activityContent of lesson.activityContents) {
                    let skillKey = activityContent.languageSkill ? activityContent.languageSkill.name : null;
                    let grammarKey = activityContent.grammarRule ? activityContent.grammarRule.name : null;
                    let vocabularyKey = activityContent.vocabulary ? activityContent.vocabulary.name : null;

                    // skills
                    skillKey && pushLessonIfIncluded(skillMap, skillKey, lesson);
                    // grammars
                    grammarKey && pushLessonIfIncluded(grammarMap, grammarKey, lesson);
                    // vocabulary
                    vocabularyKey && pushLessonIfIncluded(vocabularyMap, vocabularyKey, lesson);
                }
            }
        }
        // for each string[] array in the map, sort that array lexicographically
        orderLessons(skillMap);
        orderLessons(grammarMap);
        orderLessons(vocabularyMap);

        // for each map, order it according to values
        orderMap(skillMap);
        orderMap(grammarMap);
        orderMap(vocabularyMap);
    }
}

function pushLessonIfIncluded(map: Map<string, string[]>, key: string, lesson: Lesson) {
    let includedInLessons = map.get(key);

    if (includedInLessons) {
        if (!includedInLessons.includes(lesson.title)) {
            includedInLessons.push(lesson.title);
        }
    } else {
        let lessonsArray = new Array();
        lessonsArray.push(lesson.title);
        map.set(key, lessonsArray);
    }
}

/**
 * Apply selected filter on movie lessons.
 * Three filters are involved : grammarFiler, SkillFilter and vocabularyFilter.
 * Right now, AND of filters is used to filter input lessons array
 *
 * @param lessons
 * @param grammarFilter
 * @param skillFilter
 * @param vocabularyFilter
 */
function applyFilters(lessons: Lesson[], grammarFilter?: string, skillFilter?: string, vocabularyFilter?: string): Lesson[]  {
    let filteredLessons:Lesson[] = [] ;

    const grammarRuleFilterActive = (grammarFilter !== undefined);
    const languageSkillFilterActive = (skillFilter !== undefined);
    const vocabularyFilterActive = (vocabularyFilter !== undefined);
    const filterActive = grammarRuleFilterActive || languageSkillFilterActive || vocabularyFilterActive;

    if (!filterActive) {
        return lexicographicSort(lessons); // no filtering return original array
    }

    if (lessons) {
        lessons.forEach(lesson => {
            if (lesson.activityContents) {
                
                let vocabularyForLesson = new Set<string>();
                let grammarRulesForLesson = new Set<string>();
                let languageSkillsForLesson = new Set<string>();

                // collect filters from all activity contents per type
                lesson.activityContents.forEach(activityContent => {
                    activityContent.vocabulary && vocabularyForLesson.add(activityContent.vocabulary.name);
                    activityContent.languageSkill && languageSkillsForLesson.add(activityContent.languageSkill.name);
                    activityContent.grammarRule && grammarRulesForLesson.add(activityContent.grammarRule.name);
                });

                // if filters are active, try intersection between selected filter and collected filters from lesson
                if ((!grammarRuleFilterActive || (grammarRuleFilterActive && (lessonFilterFulfilled(grammarRulesForLesson, grammarFilter)))) &&
                    (!languageSkillFilterActive || (languageSkillFilterActive && (lessonFilterFulfilled(languageSkillsForLesson, skillFilter)))) &&
                    (!vocabularyFilterActive || (vocabularyFilterActive && (lessonFilterFulfilled(vocabularyForLesson, vocabularyFilter))))) {
                    let lessonCopy = _.cloneDeep(lesson);
                    // filter containing activities
                    applyFiltersToActivityContents(lessonCopy, grammarFilter, skillFilter, vocabularyFilter);
                    filteredLessons.push(lessonCopy);
                }
                
            }
        });
    }
    return Array.from(lexicographicSort(filteredLessons));
}

function applyFiltersToActivityContents(lesson: Lesson, grammarFilter?: string, skillFilter?: string, vocabularyFilter?: string) {
    let filteredActivities:ActivityContent[] = [];

    const grammarRuleFilterActive = (grammarFilter !== undefined);
    const languageSkillFilterActive = (skillFilter !== undefined);
    const vocabularyFilterActive = (vocabularyFilter !== undefined);
    const filterActive = grammarRuleFilterActive || languageSkillFilterActive || vocabularyFilterActive;

   
    if (lesson.activityContents) {
        lesson.activityContents.forEach(activiyContent => {
            if (!filterActive) {
                filteredActivities.push(activiyContent); 
            } else {
                // if filters are active, try intersection between selected filter and collected filters from lesson
                let grammarRuleName = activiyContent.grammarRule == undefined ? "" : activiyContent.grammarRule.name;
                const languageSkillName = activiyContent.languageSkill == undefined ? "" : activiyContent.languageSkill.name;
                const vocabularyName = activiyContent.vocabulary == undefined ? "" : activiyContent.vocabulary.name;
                if ((!grammarRuleFilterActive || (grammarRuleFilterActive && (activityFilterFulfilled(grammarRuleName, grammarFilter) ))) &&
                    (!languageSkillFilterActive || (languageSkillFilterActive && (activityFilterFulfilled(languageSkillName, skillFilter)))) &&
                    (!vocabularyFilterActive || (vocabularyFilterActive && (activityFilterFulfilled(vocabularyName, vocabularyFilter))))) {
                        filteredActivities.push(activiyContent);
                }
            }
        });
    }

    lesson.activityContents = filteredActivities;
}

function lessonFilterFulfilled(set: Set<string>, filter?: string) {
    return filter ? set.has(filter) : true;
}

function activityFilterFulfilled(name: string, filter?: string) {
   return filter ? name === filter : true;
}

function lexicographicSort(lessons: Lesson[]) {
    return lessons.sort((lesson1, lesson2) => {
        return lesson1.title.localeCompare(lesson2.title, 'en', { numeric: true });
    });
}

function lexicographicSortStrings(lessons: string[]) {
    return lessons.sort((lesson1, lesson2) => {
        return lesson1.localeCompare(lesson2, 'en', { numeric: true });
    });
}

export default MovieDetail;
