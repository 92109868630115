import { doFetch, apiUrl} from "./service";

export const RESPONSE_GENERAL_ERROR = 'error';
export const RESPONSE_EMAIL_NOT_FOUND = '404';

export const publicServiceUrl =  apiUrl + '/dict-service-rest/resources/public';

export function forgotPassword(email) {
    return doFetch(publicServiceUrl + '/forgotPassword/' + email,
        {
            method : 'GET'
        },
        null
    ).then(
        (response) => {
            if (response.ok) {                
                return Promise.resolve();
            } else if (response.status === 404) {
                return Promise.reject(RESPONSE_EMAIL_NOT_FOUND);
            } else {
                return Promise.reject(RESPONSE_GENERAL_ERROR);
            }
        },
        () => {
            return Promise.reject(RESPONSE_GENERAL_ERROR);
        }
    );
}