// Fetcher.js
import React from 'react';
import useFetcher from './useFetcher';

interface FetcherProps {
    action: any,
    postLoadAction?: any,
    onActionError?: any,

    children?(data: any): any,
}

const Fetcher: React.FunctionComponent<FetcherProps> = (props) => {

    const {action, postLoadAction, onActionError, children} = props;
    const fetched = useFetcher(action);

    if (fetched.loading) return <div className="loader"/>;
    if (fetched.error) return onActionError ? onActionError : <div>{fetched.error}</div>;
    if (!fetched.data) return null;

    postLoadAction && postLoadAction(fetched.data);

    return children
        ? <div>{children(fetched.data)}</div>
        : null
};

export default Fetcher;