import React, {useCallback, useEffect} from "react";
import appHistory from "../../../services/history";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../../store/type";
import {logout as userLogout} from "../../../store/reducer-user";
import {clearMovieDetail} from "../../../store/reducer-movie-detail";
import {logout} from "../../../services/SystemResource";
import {clearError} from "../../../store/reducer-gui-events";

const LogoutPage: React.FunctionComponent<{}> = () =>  {

    const dispatch = useDispatch<Dispatch>();
    const logoutCallback = useCallback(() => dispatch(userLogout()), [dispatch]);
    const clearMovieDetailCallback = useCallback(() => dispatch(clearMovieDetail()), [dispatch]);
    const clearErrorCallback = useCallback(() => dispatch(clearError()), [dispatch]);

    useEffect(() => {
        logout().then(
            async () => {
                appHistory.push("/");
                await logoutCallback();
                await clearMovieDetailCallback();
                await clearErrorCallback();
            }
        )
    });

    return <h1>Logging out  ...</h1>
};

export default LogoutPage;