import React from 'react';
import introCompsImg from "../../images/intro-comps.png"
import {PAGE_LANG_FUNCTIONS, PAGE_LEARNING_CENTER, PAGE_MOVIES_LIST} from "../common/navigation/Navigation";
import HomeItem from './HomeItem';
import {useTranslation} from "react-i18next";

const HomePage: React.FunctionComponent<{}> = () =>  {

    const { t } = useTranslation();

    return (
        <>
        <section className="intro-block">
            <div className="box-intro">
                <div className="box">
                <div className="col-2">
                    <h1>{t("welcome")}</h1>
                    <p>
                    {t("portalDesc")}
                    </p>
                </div>
                </div>
                <div className="intro-img"><img src={introCompsImg} alt=""/></div>
                <br className="cl"/>
            </div>
            </section>
            <section className="intro-continue light mft">
            <div className="box">
                <div className="box-container">
                <HomeItem heading={t("lessonsByMovies")} boxCss="col-3" iconCss="icon-movie" linkText={t("planLesson")}  url={PAGE_MOVIES_LIST} text={t("setLessonbyMovie")}/>
                <HomeItem heading={t("lessonsByLinguisticPhenomena")} boxCss="col-3" iconCss="icon-list" linkText={t("planLangPoints")} url={PAGE_LANG_FUNCTIONS} text={t("setLessonbyLinguisticPhenomena")}/>
                <HomeItem heading={t("howTeach")} boxCss="col-3 col-white" iconCss="icon-box" linkText={t("learningCenter")}  url={PAGE_LEARNING_CENTER} text={t("goToHelp")}/> 
                <br className="cl"/>
                </div>
            </div>            
        </section>
        </>    
        
    );
};

export default HomePage;
