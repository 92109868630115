import React, {useCallback} from 'react';
import Fetcher from "../../hooks/Fetcher";
import {getMovieDetail} from "../../services/ContentResource";
import {Redirect, RouteComponentProps} from "react-router-dom";
import MovieDetailPage from "./MovieDetailPage";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../store/type";
import {setMovieDetail, useMovieDetail} from "../../store/reducer-movie-detail";

type MovieDetailProps = { movieId: string | undefined };

const MovieDetailPageContainer: React.FunctionComponent<RouteComponentProps<MovieDetailProps>> = (props) => {
    const reduxDispatch = useDispatch<Dispatch>();
    const {movieId} = props.match.params;
    const {movie, reloadFlag} = useMovieDetail();
    const setMovie = useCallback((movie) => reduxDispatch(setMovieDetail({movie})), [reduxDispatch]);
    const getMovie = useCallback(() => movieId ? getMovieDetail(movieId) : null, [movieId]);
    /**
     * It can happen that moviedId is null or undefined if accessing detail via url link.
     * Fetcher is activated only if movie is not yet loaded, or movie with different id is requested
     */
    return (
        movieId
            ?
            !movie || ("" + movie.id !== movieId) || reloadFlag
                ?
                <Fetcher
                    action={getMovie}
                    postLoadAction={setMovie}
                    onActionError={<Redirect to="/movie-list"/>}/>
                :
                <MovieDetailPage movie={movie}/>
            :
            <Redirect to="/movie-list"/>
    )
};

export default MovieDetailPageContainer;
