import React, {useContext} from 'react';
import {MovieListFiltersContext} from "../../../context/MovieListFiltersContext";

interface TagProps {
    name: string;
    lessons?: string[];
    active?: boolean;
    displayLessons?: boolean;
    enabled: boolean;
    keepCursor?: boolean;
    hidden?: boolean;

    onSelect?(name: string): any;

    onDeselect?(name: string): any;
}

const Tag: React.FunctionComponent<TagProps> = (props) => {
    const {name, lessons, active, displayLessons, enabled, keepCursor,  onSelect, onDeselect} = props;
    let {state} = useContext(MovieListFiltersContext);
    const hidden = isHidden(name, state.searchFilter);
    return (
        <button className={resolveCss(enabled, keepCursor, active, hidden)} onClick={() => {
            if (enabled) {
                active ? (onDeselect && onDeselect(name)) : (onSelect && onSelect(name));
            }
        }}>
            <span className="tag-title">{name}</span>
            {lessons && displayLessons && <span>{joinLessons(lessons)}</span>}
        </button>
    );
};

function isHidden(name: string, searchPattern?: string): boolean {
    if (searchPattern && name.toLowerCase().search(searchPattern.toLowerCase()) != -1) {
        return false;
    } else if (searchPattern) {
        return true;
    }
    return false;
}

function joinLessons(lessons: string[]): string {
    let strings = "";
    lessons.forEach(lesson => {
        strings = strings + lesson + ","
    });
    return strings.length > 0 ? (" (" + strings.slice(0, -1) + ") ") : "";
}

function resolveCss(enabled: boolean, keepCursor?: boolean, active?: boolean, hidden?: boolean) {
    let css = "button-link-detail ";
    if (active) {
        css += "active ";
    }
    if (!enabled) {
        css += "disabled ";
    }
    if (keepCursor) {
        css += "no-hand";
    }
    if (hidden) {
        css += "hidden-in-search";
    }
    return css;
}

export default Tag;
