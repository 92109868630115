import {combineReducers, Reducer} from "redux";

import {Actions, State} from "./type";
import {reducer as reducerMovieList} from "./reducer-movie-list";
import {reducer as reducerMovieDetail} from "./reducer-movie-detail";
import {reducer as reducerUser} from "./reducer-user";
import {reducer as reducerActivityTypes} from "./reducer-activity-type";
import {reducer as reducerPlayerData} from "./reducer-player-data";
import {reducer as reducerGuiEvents} from "./reducer-gui-events";

export const reducer: Reducer<State, Actions> = combineReducers({
    reducerMovieList: reducerMovieList,
    reducerMovieDetail: reducerMovieDetail,
    reducerUser: reducerUser,
    reducerActivityTypes: reducerActivityTypes,
    reducerPlayerData: reducerPlayerData,
    reducerGuiEvents: reducerGuiEvents
});