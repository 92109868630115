import React from 'react';
import {Difficulty} from "../../../interfaces/MovieInterface";
import {formatDifficulty} from "../../../utils/FormattingUtils";
import { useTranslation } from 'react-i18next';

interface DifficultyFilterItemProps {
    difficulty: Difficulty;
    active: boolean;

    onSelect(difficulty: Difficulty): void;

    onDeselect(difficulty: Difficulty): void;
}

const DifficultyFilterItem: React.FunctionComponent<DifficultyFilterItemProps> = (props) => {
    const {difficulty, active, onDeselect, onSelect} = props;
    const { t } = useTranslation();
    const formatedDifficulty = formatDifficulty(difficulty);
    return (
        <>
            <li>
                <button className={active ? "active button-link icon-"+ difficulty: "button-link icon-" + difficulty} onClick={() => {
                    if (active) onDeselect(difficulty); else onSelect(difficulty);
                }}><span></span>{t("difficulty" + difficulty)}</button>
            </li>
        </>
    );
};

export default DifficultyFilterItem;
