// useFetcher.js
import {useState, useEffect} from 'react';

interface Fetched {
    loading: boolean;
    error: any;
    data: any;
}

export default function useFetcher(action: any): Fetched {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<any>(null);

    async function loadData() {
        try {
            setLoading(true);
            const actionData = await action();
            setData(actionData);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [action]);

    return {data : data, loading: loading, error: error};
}