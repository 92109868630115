import React, {useCallback, useContext, useEffect} from 'react';
import DifficultyFilter from "./DifficultyFilter";
import LanguageLinguisticFilter from "./LanguageLinguisticFilter";
import SearchFilter from "./SearchFilter";
import SkillsGrammarVocabularyFilter from "./SkillsGrammarVocabularyFilter";
import {MovieListFiltersContext} from "../../../context/MovieListFiltersContext";
import {setGrammarSkillVocabulary, useFilteredMovieList} from "../../../store/reducer-movie-list";
import {getSkillsGrammarsVocabulary} from "../MovieListPageContainer";
import SelectedSkillGrammarVocabulary from "./SelectedSkillGrammarVocabulary";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../../store/type";


const LinguisticPhenomenaFilter: React.FunctionComponent<{}> = () => {
    const {state} = useContext(MovieListFiltersContext);
    const {skillFilter, grammarFilter, vocabularyFilter} = state;
    const extendedFilter = !(skillFilter || grammarFilter || vocabularyFilter);
    const dispatch1 = useDispatch<Dispatch>();
    const filteredMovies = useFilteredMovieList();

    const setGrammarSkillVocabularyCallback = useCallback((fm) => dispatch1(setGrammarSkillVocabulary({
        ...getSkillsGrammarsVocabulary(fm)
    })), [dispatch1]);

    useEffect(() => {
        setGrammarSkillVocabularyCallback(filteredMovies);
    }, [filteredMovies]);

    return (
        <>
            <div className="movies-list-filter filter-big">
                <LanguageLinguisticFilter/>
                <br className="cl"/>
                <DifficultyFilter/>
                <br className="cl"/>
                <SelectedSkillGrammarVocabulary/>
                <br className="cl"/>
                {extendedFilter && <SkillsGrammarVocabularyFilter/>}
            </div>
        </>
    )
};

export default LinguisticPhenomenaFilter;
