import React, {useCallback, useContext, useEffect} from 'react';
import DifficultyFilter from "./DifficultyFilter";
import LanguageFilter from "./LanguageFilter";
import SearchFilter from "./SearchFilter";
import SkillsGrammarVocabularyFilter from "./SkillsGrammarVocabularyFilter";
import {MovieListFiltersContext} from "../../../context/MovieListFiltersContext";
import {setGrammarSkillVocabulary, useFilteredMovieList} from "../../../store/reducer-movie-list";
import {getSkillsGrammarsVocabulary} from "../MovieListPageContainer";
import SelectedSkillGrammarVocabulary from "./SelectedSkillGrammarVocabulary";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../../store/type";


const MoviesFilter: React.FunctionComponent<{}> = () => {
    const dispatch1 = useDispatch<Dispatch>();
    const filteredMovies = useFilteredMovieList();

    const setGrammarSkillVocabularyCallback = useCallback((fm) => dispatch1(setGrammarSkillVocabulary({
        ...getSkillsGrammarsVocabulary(fm)
    })), [dispatch1]);

    useEffect(() => {
        setGrammarSkillVocabularyCallback(filteredMovies);
    }, [filteredMovies]);

    return (
        <>
            <div className="movies-list-filter filter-big">
                <LanguageFilter/>
                <br className="cl"/>
                <DifficultyFilter/>
                <br className="cl"/>
            </div>
        </>
    )
};

export default MoviesFilter;
