import React from "react"
import {useTranslation} from "react-i18next";
import CookieConsent from "react-cookie-consent";

/**
 * Cookie consent dialog, inform user that cookies are used to enhance user experience.
 * Component itself set Cookie if user already agreed with consent
 * Cookie name : consentCookie
 * Cookie value : true / undefined
 * Cookie expiration : 1 year
 * @constructor
 */
const MooveezCookieConsent : React.FunctionComponent<{}> = () => {
    const { t } = useTranslation();
    return (
        <CookieConsent
            location="bottom"
            buttonText={t("agree")}
            cookieName="consentCookie">
            {t("cookieConsent")}
        </CookieConsent>
    )
};



export default MooveezCookieConsent