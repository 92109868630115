/**
 * Transform bytes to human readable format (kB, MB, GB ...)
 * @param bytes
 * @param si
 */
import {Difficulty, Movie} from "../interfaces/MovieInterface";
import * as i18next from "i18next";

export const DEFAULT_ARRAY_TOSTRING_SEPARATOR="-";

export function formatFileSize(bytes: number, si: boolean) {
    let thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    let units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}

export function formatArrayWithSeparator(property : string, array?: Array<any>, separator ?: string) : string {
    let arrayToFormat = new Array<any>();
    if (array) {

        array.forEach(value => {
            if (value) {
                arrayToFormat.push(value[property]);
            }
        });

        if (separator) {
            return arrayToFormat.join(separator)
        } else {
            return arrayToFormat.join(DEFAULT_ARRAY_TOSTRING_SEPARATOR);
        }
    }
    return "";
}


export function formatDifficultyWithExplanation(difficulty: Difficulty, t: i18next.TFunction) {
   return t("difficulty" + difficulty);
}

export function formatDifficulty(difficulty : Difficulty) {
    switch (difficulty) {
        case Difficulty.A : return "A";
        case Difficulty.B : return "B";
        case Difficulty.C : return "C";
    }
    return "";
}

export function formatTimeForVideoPosition(videoPositionStart : number, videoPositionEnd: number) {
    return formatTimeForSentence(videoPositionStart).clock + "-" + formatTimeForSentence(videoPositionEnd).clock;
}

export function formatTimeForSentence(timeInMillis : number) {
    let hours = Math.floor(timeInMillis / 3600000); // 1 Hour = 36000 Milliseconds
    let minutes = Math.floor((timeInMillis % 3600000) / 60000); // 1 Minutes = 60000 Milliseconds
    let seconds = Math.floor(((timeInMillis % 360000) % 60000) / 1000); // 1 Second = 1000 Milliseconds
    return {
        hours : hours,
        minutes : minutes,
        seconds : seconds,
        clock : (hours > 0 ? (hours + ":") : "") + formatToDoubleZeros(minutes) + ":" + formatToDoubleZeros(seconds)
    };
}

export function formatMillisToMinutes(timeInMillis : number) {
    return Math.floor(timeInMillis/(60000));
}

export function formatToDoubleZeros(number: number) :  string {
    return number < 10 ? ("0" + number) : ("" + number);
}

export function resolveDifficultyCss(movie: Movie) {
    let grade = "";
    switch (movie.difficulty) {
        case Difficulty.A : {
            grade = "easy";
            break;
        }
        case Difficulty.B : {
            grade = "medium";
            break;
        }
        case Difficulty.C : {
            grade = "hard";
            break
        }
    }
    return "difficulty " + grade;
}

export function resolveFlagCss(movie: Movie) {
    return "flag " + movie.languageVariant.languageCode.toLowerCase();
}

