import React from 'react';
import MovieParams from "./MovieParams";
import MovieStats from "./MovieStats";
import {Movie} from "../../../interfaces/MovieInterface";
import {getMediaPath} from "../../../utils/ResourceBasedUtils";

interface MovieDataProps {
    movie: Movie;
}

const MovieData: React.FunctionComponent<MovieDataProps> = (props) => {
    const {movie} = props;
    return (
        <>
            <a href="#1" className="movie-img"><img src={getMediaPath(movie.posterImage)} alt=""/></a>
            <div className="movie-data">
                <h1>{movie.name}</h1>
                <div>
                    <MovieParams difficulty={movie.difficulty}
                                 videoFileSize={movie.video.size}
                                 published={movie.published}
                                 availableTranslations={movie.movieTranslations}/>
                    <MovieStats duration={movie.video.duration} // TODO DMA
                                wordCount={movie.distinctBaseWordCount}
                                includedInLessons={movie.lessonCount}
                                phraseCount={movie.phraseCount}
                                exerciseCount={movie.exerciseCount}/>
                    <br className="cl"/>
                </div>
                <p>{movie.description}</p>
            </div>
        </>
    );
};

export default MovieData;
