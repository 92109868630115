import {Actions} from "../type";
import {Movie} from "../../interfaces/MovieInterface";
import {ActionTypes} from "./action";

export type ReducerMovieState = {
    movie?: Movie;
    reloadFlag?: boolean;
};

const initialState: Readonly<ReducerMovieState> = {
};

export function reducer(state: ReducerMovieState = initialState, action: Actions): ReducerMovieState {
    switch (action.type) {
        case ActionTypes.SET_MOVIE_DETAIL: {
            return {
                ...state,
                ...action.payload,
                reloadFlag: false
            };
        }
        case ActionTypes.CLEAR_MOVIE_DETAIL: {
            return {};
        }
        case ActionTypes.SET_RELOAD_FLAG: {
            return {...state,
                reloadFlag: true
            };
        }
        default: {
            return state;
        }
    }
}