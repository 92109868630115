import React from 'react';
import {ErrorMessage, Field} from 'formik';
import * as Lodash from "lodash";

export interface InputProps {
    name : string,
    type : string,
    errors ?: any,
    touched ?: any,
    wrapper ?: any,
    br ?: boolean,
    placeholder ?: string
    className ?: string,
    autocomplete ?: string,
    autocapitalize?: string
}

const Input : React.FunctionComponent<InputProps> = (props) => {
    const {name, type, errors, touched, wrapper, placeholder, autocomplete, autocapitalize} = props;
    const WrapperTag = wrapper ? wrapper : React.Fragment;
    return (
        <WrapperTag>
            <Field name={name}
                   type={type}
                   className={Lodash.get(errors, name) && Lodash.get(touched, name) && "val-error"}
                   placeholder={placeholder}
                   autoComplete={autocomplete}
                   autoCapitalize={autocapitalize}
            />
            <ErrorMessage name={name}/>
        </WrapperTag>
    )
};

export default Input;