import React, {useContext, useEffect, useRef, useState} from 'react';
import {MovieListFiltersContext} from "../../../context/MovieListFiltersContext";
import '../../../localization/i18n';
import {useTranslation} from 'react-i18next';
import { PAGE_LANG_FUNCTIONS } from '../../common/navigation/Navigation';

const SearchFilter: React.FunctionComponent<{}> = () => {

    let {state, dispatch} = useContext(MovieListFiltersContext);
    let [showSearchInput, setShowSearchInput] = useState(false);

    const searchInputRef = useRef(null);
    
    const { t } = useTranslation();

    const setMovieListSearchFilter = (searchPattern: string) => dispatch({
        type: 'setSearchFilter',
        searchPattern: searchPattern
    });

    function isSearchActive(): boolean {
        return (state.searchFilter && state.searchFilter.length > 0) ? true :  false;
    }

    function handleKeyPress(event) {
        if (event.keyCode === 27) {
            setMovieListSearchFilter("");
            setShowSearchInput(false);
        }
    }

    useEffect(() => {
        if (showSearchInput) {
            // @ts-ignore
            searchInputRef.current.focus();
        }
    }, [showSearchInput]);

    return (
        <div className="filter-search">
            <ul>
                <li className="icon-search">
                        <span className={"button-link" + ((showSearchInput || isSearchActive()) ? " active" : "")}
                              onClick={() => setShowSearchInput(showSearchInput ? false : true)}>
                            {state.page === PAGE_LANG_FUNCTIONS ? t('searchLangPoints') : t("searchMovie")}
                        </span>
                    {(showSearchInput || isSearchActive()) &&
                    <div className="search-inline">
                        <input ref={searchInputRef}
                               value={state.searchFilter}
                               type="text"
                               onChange={(e) => setMovieListSearchFilter(e.target.value)}
                               onKeyDown={handleKeyPress}/>
                    </div>
                    }
                </li>               
            </ul>
        </div>
    )
};

export default SearchFilter;
