import React from 'react';

export type FaqItemProps = { 
    q: string;
    a: string;
 };

const FaqItem: React.FunctionComponent<FaqItemProps> = (props) =>  {
    const {q, a} = props;
    return (
        <div className="article-box">            
            <strong>{q}</strong> 
            <p>{a}</p>
           
        </div>
    );
};

export default FaqItem;