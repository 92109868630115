import {Actions} from "../type";
import {SentenceItem} from "../../interfaces/MovieInterface";
import {ActionTypes} from "./action";

export type ReducerPlayerDataState = {
    sentences?: SentenceItem[],
    videoUrls: {
        mpd: string | undefined,
        m3u?: string | undefined
    },
};

const initialState: Readonly<ReducerPlayerDataState> = {videoUrls: {mpd:undefined}
};

export function reducer(state: ReducerPlayerDataState = initialState, action: Actions): ReducerPlayerDataState {
    switch (action.type) {
        case ActionTypes.SET_PLAYER_DATA: {
            return {...state, ...action.payload};
        }
        case ActionTypes.CLEAR_PLAYER_DATA: {
            return {videoUrls: {mpd:undefined}};
        }
        default: {
            return state;
        }
    }
}