import React from 'react';
import {Field, Form, Formik, FormikActions, FormikProps} from "formik";
import LabeledInput from "../form/LabeledInput";
import * as Yup from "yup";

export enum PasswordRestoreResultState {
    RESULT_SUCCESS,
    RESULT_EMAIL_NOT_FOUND,
    RESULT_FAILURE
}

export interface PasswordRestoreInfo {
    email: string;
}

interface PasswordRestoreProps {
    resultStatus?: PasswordRestoreResultState;

    onPasswordRestore(email: string): void;
}

const initialPasswordRestoreInfo: PasswordRestoreInfo = {email: ""};

const PasswordRestore: React.FunctionComponent<PasswordRestoreProps> = (props) => {

    const {resultStatus, onPasswordRestore} = props;

    return (
        <div className="form-col-1">
            <Formik
                initialValues={initialPasswordRestoreInfo}
                onSubmit={(passwordRestoreInfo: PasswordRestoreInfo, actions: FormikActions<PasswordRestoreInfo>) => {
                    onPasswordRestore(passwordRestoreInfo.email);
                }}
                validationSchema={PasswordRestoreSchema}
                render={(formikBag: FormikProps<PasswordRestoreInfo>) => (
                    <Form noValidate>
                        <LabeledInput label="Email" name="email" type="email" autocomplete="email"/>
                        <div>
                            <Field id="submit" type="submit" value="Odeslat" className="send-btn"/>
                        </div>
                    </Form>
                )}
            />

            {resultStatus === PasswordRestoreResultState.RESULT_SUCCESS &&
                <p>Na zadanou emailovou adresu byl odeslán email s instrukcemi k obnově hesla.</p>
            }
            {resultStatus === PasswordRestoreResultState.RESULT_EMAIL_NOT_FOUND &&
                <p className="error">Bohužel nebyl nalezen žádný uživatel pro zadaný email</p>
            }
            {resultStatus === PasswordRestoreResultState.RESULT_FAILURE &&
                <p className="error">Při požadované akci došlo k chybě.</p>
            }

            <p>V případě dotazů nás kontaktujte na <strong><a
                href="mailto:info@mooveez.com">info@mooveez.com</a></strong></p>

        </div>
    );
};

const PasswordRestoreSchema = Yup.object().shape({
    email: Yup.string()
        .email("Formát emailu není platný").required("Email je povinný")
});

export default PasswordRestore;
