import {ActionsUnion, DispatchAction} from "../type";
import {createAction} from "../action";
import {SentenceItem} from "../../interfaces/MovieInterface";

export enum ActionTypes {
    SET_PLAYER_DATA = "setPlayerData",
    CLEAR_PLAYER_DATA = "clearPlayerData",
}

export type ActionSetPlayerDataOptions = {
    sentences?: SentenceItem[],
    videoUrls?: {
        mpd: string | undefined,
        m3u?: string | undefined
    }
};

export const Actions = {
    setPlayerData: (options: ActionSetPlayerDataOptions) => createAction(ActionTypes.SET_PLAYER_DATA, options),
    clearPlayerData: () => createAction(ActionTypes.CLEAR_PLAYER_DATA),
};

export type Actions = ActionsUnion<typeof Actions>;

export function setPlayerData(options: ActionSetPlayerDataOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.setPlayerData(options));
    };
}

export function clearPlayerData(): DispatchAction {
    return async dispatch => {
        dispatch(Actions.clearPlayerData());
    };
}