import React, {ReactNode, useState} from 'react';
import {ActivityContent, ActivityType} from "../../../interfaces/MovieInterface";
import ActivityTypeDetail from "./ActivityTypeDetail";
import '../../../localization/i18n';
import {useTranslation} from 'react-i18next';
import {useActivityTypes} from "../../../store/reducer-activity-type";

interface ActivityContentProps {
    activityContent: ActivityContent;
}

const ActivityContentDetail: React.FunctionComponent<ActivityContentProps> = (props) => {
    const {activityContent} = props;
    const {t} = useTranslation();
    const [selectedActivity, setSelectedActivity] = useState();
    const activityTypesMap = useActivityTypes();

    function toggleSelection(activityType: ActivityType) {
        if (selectedActivity === activityType) {
            setSelectedActivity(null)
        } else {
            setSelectedActivity(activityType)
        }
    }

    return (activityContent &&
        <>
            <h5>{t('recommendedActivities')}:</h5>
            <div className="activities">
                {renderTabs(activityTypesMap.activityTypeMap, activityContent, selectedActivity, toggleSelection)}
            </div>
            <div className="cl"/>
            {selectedActivity && <ActivityTypeDetail
                activityContent={activityContent}
                key={selectedActivity.id}
                activityType={getActivityTypeById(activityTypesMap.activityTypeMap, selectedActivity.id)}
                sentences={activityContent.sentences}/>}
        </>
    );

};

export function resolveActivityTypes(activityContentMap: Map<number, ActivityType>, activityContent: ActivityContent) {
    let activityTypesModified = new Array<ActivityType>();
    for (let i = 0; i < activityContent.activityTypes.length; i++) {
        if (activityContent.activityTypes[i]) {
            activityTypesModified.push(getActivityTypeById(activityContentMap, activityContent.activityTypes[i].id));
        }
    }

    activityTypesModified.sort(function (at1, at2) {
        return at1.typeOrder - at2.typeOrder;
    });
    return activityTypesModified;
}


function renderTabs(activityContentMap: Map<number, ActivityType>, activityContent: ActivityContent, selectedActivityType: ActivityType, selectionHandler: any): Array<ReactNode> {
    let tabs = new Array<ReactNode>();
    let activityTypes = resolveActivityTypes(activityContentMap, activityContent);
    for (let activityType of activityTypes) {
        tabs.push(renderTab(activityType, selectedActivityType, selectionHandler));
    }
    return tabs;
}


function renderTab(activityType: ActivityType, selectedActivityType: ActivityType, selectionHandler: any) {
    return <button key={activityType.id} type="button"
                   className={(selectedActivityType && selectedActivityType.id === activityType.id) ? "active" : ""}
                   onClick={() => selectionHandler(activityType)}>
        {activityType.title}
    </button>
}

function getActivityTypeById(activityContentMap: Map<number, ActivityType>, id: number): any {
    return activityContentMap ? activityContentMap.get(id) : null;
}

export default ActivityContentDetail;
