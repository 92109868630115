import {ActionsUnion, DispatchAction} from "../type";
import {createAction} from "../action";
import {Movie} from "../../interfaces/MovieInterface";

export enum ActionTypes {
    SET_MOVIE_DETAIL = "setMovieDetail",
    CLEAR_MOVIE_DETAIL = "clearMovieDetail",
    SET_RELOAD_FLAG = "setReloadFlag"
}

export type ActionSetMovieOptions = {
    movie: Movie;
};

export type ActionSetReloadFlagOptions = {
    reloadFlag: boolean;
};

export const Actions = {
    setMovieDetail: (options: ActionSetMovieOptions) => createAction(ActionTypes.SET_MOVIE_DETAIL, options),
    setReloadFlag: (options: ActionSetReloadFlagOptions) => createAction(ActionTypes.SET_RELOAD_FLAG, options),
    clearMovieDetail: () => createAction(ActionTypes.CLEAR_MOVIE_DETAIL),
};

export type Actions = ActionsUnion<typeof Actions>;

export function setMovieDetail(options: ActionSetMovieOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.setMovieDetail(options));
    };
}

export function clearMovieDetail(): DispatchAction {
    return async dispatch => {
        dispatch(Actions.clearMovieDetail());
    };
}

export function setReloadFlag(options: ActionSetReloadFlagOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.setReloadFlag(options));
    };
}