import React from 'react';
import Section from "../common/section/Section";
import {Movie} from "../../interfaces/MovieInterface";
import appHistory from "../../services/history"
import MovieDetail from "./detail/MovieDetail";
import '../../localization/i18n';
import { useTranslation } from 'react-i18next';

interface MovieDetailPageProps {
    movie : Movie;
    useLinguisticPhenomena?: boolean;
}

const MovieDetailPage: React.FunctionComponent<MovieDetailPageProps> = (props) => {
    const {movie, useLinguisticPhenomena} = props;
    const { t } = useTranslation();
    return (
        <Section>
            <button onClick={appHistory.goBack} className="button-link big">&lt; {t('back')}</button>
            <MovieDetail useLinguisticPhenomena movie={movie}/>
            <br className="cl"/>
            <p>&nbsp;</p>
        </Section>
    )
};

export default MovieDetailPage;
