import React from 'react';
import Tag from "./Tag";

interface TagListProps {
    type: string;
    title: string;
    tags?: Map<string, string[]>;
    selectedTags?: string[];
    enabledTags?: string[]
    displayLessons?: boolean;

    onSelect(name: string): any;

    onDeselect(name: string): any;
}

const TagList: React.FunctionComponent<TagListProps> = (props) => {
    const {type, title, tags, selectedTags, displayLessons, enabledTags, onDeselect, onSelect} = props;
    return (
        tags && tags.size > 0
            ?
            <div className={type}>
                <h2>{title}</h2>
                {tags && Array.from(tags.entries()).map((tagEntry) => {
                    const name = tagEntry[0];
                    const lessons = tagEntry[1];
                    const active = selectedTags && selectedTags.includes(name);
                    return <Tag
                        key={name}
                        name={name}
                        enabled={enabledTags ? enabledTags.includes(name) : true}
                        lessons={lessons}
                        active={active}
                        displayLessons={displayLessons}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                    />;
                })}
            </div>
            :
            null
    );
};

export default TagList;
