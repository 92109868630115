import {ActionsUnion, DispatchAction} from "../type";
import {createAction} from "../action";
import {ActivityType} from "../../interfaces/MovieInterface";

export enum ActionTypes {
    ACTIVITY_TYPES_LOADED = "activityTypesLoaded",
}

export type ActionActivitiesLoadedOptions = {
    activityTypeMap: Map<number, ActivityType>
};

export const Actions = {
    activitiesLoaded: (options: ActionActivitiesLoadedOptions) => createAction(ActionTypes.ACTIVITY_TYPES_LOADED, options),
};

export type Actions = ActionsUnion<typeof Actions>;

export function activityTypesLoaded(options: ActionActivitiesLoadedOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.activitiesLoaded(options));
    };
}