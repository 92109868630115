import {doFetch,  enhanceHeadersBasic, apiUrl} from "./service";
import {AuthDomain} from "../store/reducer-user/reducer";
import {Logger} from "../utils/Logger";
import * as crypto from "crypto-js";

// URLs for portal app REST API
export const serviceUrlPortal =  apiUrl + '/dict-service-rest-portal/webresources';
export const serviceUrlPortalSystem = serviceUrlPortal + "/system";

// URLs for general REST API
const serviceUrlRest = apiUrl + '/dict-service-rest/resources';
const serviceUrlRestPublic = serviceUrlRest + '/public';

interface AuthenticationData {
    domain: AuthDomain;
    userId: string;
    token: string;
    password?: string;
}

// interface AppleAuthenticationData {
//     identityToken: string;
//     authorizationCode: string;
// }

export function authenticate(authenticationData : AuthenticationData) {
    // get token for further authentication
    return doFetch(serviceUrlRestPublic + '/getToken',{method: 'POST',headers: {}}, removePassword(authenticationData),true)
        .then(responseData => {
            return Promise.resolve(responseData.validatedToken)})
        .then(async validatedToken => {
            return tryAuthenticatedResourcePortal(authenticationData, validatedToken);
        })
        .catch((error) => {
            return Promise.reject(error) })
}

// export function appleAuthenticate(authenticationData : AppleAuthenticationData) {
//     // get token for further authentication
//     return doFetch(serviceUrlRestPublic + '/getAppleToken',{method: 'POST',headers: {}}, authenticationData,true)
//         .then(responseData => {
//             return Promise.resolve(responseData.validatedToken)})
//         .then(async validatedToken => {
//             let authenticationData = {
//                 domain: AuthDomain.APPLE,
//                 token: validatedToken,
//                 userId: ""};
//             return tryAuthenticatedResourcePortal(authenticationData, validatedToken);
//         })
//         .catch((error) => {
//             return Promise.reject(error) })
// }

export function appleAuthenticate(validatedToken: string, email: string) {
    let authenticationData = {
        domain: AuthDomain.APPLE,
        token: validatedToken,
        userId: email};
    return tryAuthenticatedResourcePortal(authenticationData, validatedToken)
        .catch((error) => {
            return Promise.reject(error) })
}

function removePassword(authenticationData?: AuthenticationData): AuthenticationData | undefined {
    let clonedAuthData = Object.assign({}, authenticationData);
    if (clonedAuthData && clonedAuthData.password) {
        delete clonedAuthData.password;
    }
    return clonedAuthData;
}

/**
 * Try to access to authenticated resource. If userId and token is present, use it to construct Basic Authentication header - this is case during login process.
 */
export function tryAuthenticatedResource(url: string, authenticationData?: AuthenticationData, token?: string) {
    let headers = {headers: {}};
    authenticationData = removePassword(authenticationData);
    const userId = authenticationData ? authenticationData.userId : null;
    if (userId && token) {
        enhanceHeadersBasic(headers, userId, token);
    }

    return doFetch(url,{method: 'GET',headers: headers.headers}, null,true)
        .then( response => {
            return doFetch(url,{method: 'GET',headers: headers.headers}, null,true);
        })
}

export function tryAuthenticatedResourcePortal(authenticationData?: AuthenticationData, token?: string) {
    return tryAuthenticatedResource(serviceUrlPortalSystem + '/authTeachers/', authenticationData, token)
}

    /**
 * We need to logout from application (dict-service-rest-portal)
 */
export async function logout() {
    try {
        await doFetch(serviceUrlPortalSystem + '/logout', {}, null, true);
    } catch (error) {
        // do nothing
        Logger.error("Error logging out from "+serviceUrlPortalSystem, error);
    }
}

export function basicAuthentication(username: string, token: string) {
    return authenticate({
        domain: AuthDomain.BASIC,
        userId: username,
        token
    });
}

export function googleAuthentication(token: string, email: string) {
    return authenticate({
        domain: AuthDomain.GOOGLE,
        token : token,
        userId : email
    });
}

export function facebookAuthentication(token: string, email: string) {
    return authenticate({
        domain: AuthDomain.FACEBOOK,
        token: token,
        userId: email
    });
}

export function getBasicAuthenticationToken(username: string, password: string) {
    const data = {
        email: username,
        hashPassword: crypto.SHA256(password).toString()
    };
    return doFetch(serviceUrlRestPublic + "/getAuthToken", {method: 'POST', headers: {}}, data, true)
        .then((response) => {
            return Promise.resolve(response.accessToken);
    })
}
