import React, {useContext} from 'react';
import {MovieListFiltersContext} from "../../../context/MovieListFiltersContext";
import TagList from "../detail/TagList";
import {useGrammarMap, useSkillMap, useVocabularyMap} from "../../../store/reducer-movie-list";
import '../../../localization/i18n';
import { useTranslation } from 'react-i18next';

const SkillsGrammarVocabularyFilter: React.FunctionComponent<{}> = () => {

    let {state, dispatch} = useContext(MovieListFiltersContext);

    const skillMap = useSkillMap();
    const grammarMap = useGrammarMap();
    const vocabularyMap = useVocabularyMap();
    const { t } = useTranslation();

    const {skillFilter, grammarFilter, vocabularyFilter} = state;

    const addGrammarFilter = (grammarRule: string) => {
        dispatch({type: "addGrammarFilter", grammar: grammarRule})
        dispatch({type: "extendedFilter", active : false})
    };
    const removeGrammarFilter = (grammarRule: string) => {
        dispatch({type: "removeGrammarFilter", grammar: grammarRule})
    };
    const addSkillFilter = (skill: string) => {
        dispatch({type: "addSkillFilter", skill: skill})
        dispatch({type: "extendedFilter", active : false})
    };
    const removeSkillFilter = (skill: string) => {
        dispatch({type: "removeSkillFilter", skill: skill})
    };
    const addVocabularyFilter = (vocabulary: string) => {
        dispatch({type: "addVocabularyFilter", vocabulary: vocabulary})
        dispatch({type: "extendedFilter", active : false})
    };
    const removeVocabularyFilter = (vocabulary: string) => {
        dispatch({type: "removeVocabularyFilter", vocabulary: vocabulary})
    };

    return (
        <div className="filter-detail">
            <TagList type="vocabulary" title={t('vocabulary')}
                     tags={vocabularyMap}
                     selectedTags={vocabularyFilter ? [vocabularyFilter] : []}
                     onSelect={addVocabularyFilter}
                     onDeselect={removeVocabularyFilter}
                     displayLessons={false}/>
            <TagList type="skills" title={t('langFunctions')}
                     tags={skillMap}
                     selectedTags={skillFilter ? [skillFilter] : []}
                     onSelect={addSkillFilter}
                     onDeselect={removeSkillFilter}
                     displayLessons={false}/>
            <TagList type="grammar" title={t('grammar')}
                     tags={grammarMap}
                     selectedTags={grammarFilter ? [grammarFilter] : []}
                     onSelect={addGrammarFilter}
                     onDeselect={removeGrammarFilter}
                     displayLessons={false}/>
            <br className="cl"/>
        </div>
    );
};

export default SkillsGrammarVocabularyFilter;
