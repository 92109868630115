import { createSelector } from "reselect";

import { State } from "../type";

import { ReducerUserState} from "./reducer";

export function selectReducerUserState(state: State): ReducerUserState {
    return state.reducerUser;
}

export const selectUserInfo = createSelector(
    selectReducerUserState,
    state => state
);
