import React from 'react';
import GoogleLogin from 'react-google-login/dist/google-login'

// for localhost testing ...
export const DEFAULT_GOOGLE_CLIENT_ID = '743450618759-jifedssqpq6eiqkicdvr71faqf82gbvi.apps.googleusercontent.com';

interface GoogleButtonProps {
    onGoogleSuccess,
    onGoogleFailure
}

const GoogleButton: React.FunctionComponent<GoogleButtonProps> = (props) => {
    const {onGoogleFailure, onGoogleSuccess} = props;
    return (
        <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || DEFAULT_GOOGLE_CLIENT_ID}
            render={renderProps => (
                <button className="loginGoogle" onClick={renderProps.onClick}>Google login</button>
            )}
            buttonText="Login"
            onSuccess={onGoogleSuccess}
            onFailure={onGoogleFailure}
        />
    );
};

export default GoogleButton;
