import React from 'react';
import {Link} from "react-router-dom"
import {PAGE_MOVIE_DETAIL_FULL} from "../../common/navigation/Navigation";
import {Movie} from "../../../interfaces/MovieInterface";
import {formatDifficultyWithExplanation, resolveDifficultyCss, resolveFlagCss} from "../../../utils/FormattingUtils";
import {getMediaPath} from "../../../utils/ResourceBasedUtils";
import {useTranslation} from "react-i18next";

const MoviesGridItem: React.FunctionComponent<{ movie: Movie }> = (props) => {
    const {movie} = props;
    const {t} = useTranslation();
    return (
        <Link to={getMovieDetailLink(movie.id)} className="movie-link">
            <img src={getMediaPath(movie.posterImage)} alt="dummy"/>
            <div>
                <span className={resolveFlagCss(movie)}></span>
                {formatDifficultyWithExplanation(movie.difficulty, t)}
                <span className={resolveDifficultyCss(movie)}></span>
            </div>
            <p>{movie.name}</p>
        </Link>
    )
};

export function getMovieDetailLink(movieId: number) {
    return PAGE_MOVIE_DETAIL_FULL + "/" + movieId;
}

export default MoviesGridItem;
