import React, {useCallback, useState, useEffect} from "react";
import Login from "./Login";
import {
    ActionLoginSuccessOptions,
    ActionSetSubscriptionStatusOptions,
    loginFailure,
    loginSuccess, setSubscriptionStatus
} from "../../../store/reducer-user";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../../store/type";
import {AuthDomain} from "../../../store/reducer-user/reducer";
import {useTranslation} from "react-i18next";
import {
    basicAuthentication,
    facebookAuthentication,
    getBasicAuthenticationToken,
    googleAuthentication,
    appleAuthenticate
} from "../../../services/SystemResource";
import {getSubscriptionStatus} from "../../../services/ContentResource";
import {publicServiceUrl} from "../../../services/PublicResource";
import appHistory from "../../../services/history";
import {PAGE_HOME} from "../../common/navigation/Navigation";

export interface LoginInfo {
    login: string; 
    password: string;
}

export interface AuthError {
    domain: AuthDomain;
    errorDescription: {
        __html: string
    };
}

// props for Sign In With Apple passed by rediect as part of the URL
export type SignInWithAppleLoginProps = { 
    appleToken: string | undefined;
    appleEmail: string | undefined;
 };

const LoginContainer: React.FunctionComponent<SignInWithAppleLoginProps> = (props) => {
    const [authError, setAuthError] = useState<AuthError | null>(null);
    // props for Sign In With Apple passed by rediect as part of the URL
    const {appleToken, appleEmail} = props;
    const dispatch = useDispatch<Dispatch>();
    const loginSuccessCallback = useCallback((userInfo: ActionLoginSuccessOptions) => dispatch(loginSuccess(userInfo)), [dispatch]);
    const loginFailureCallback = useCallback(() => dispatch(loginFailure()), [dispatch]);
    const setSubscriptionStatusCallback = useCallback((subscriptionStatus: ActionSetSubscriptionStatusOptions) => dispatch(setSubscriptionStatus(subscriptionStatus)), [dispatch]);

    const loginSuccessCommon = async (userInfo: ActionLoginSuccessOptions) => {
        setAuthError(null);
        // console.log("loginSuccessCommon", userInfo);
        await loginSuccessCallback({...userInfo});
        await setSubscriptionStatusCallback({
            subscriptionActive: await getSubscriptionStatus()
        });
    };

    const { t } = useTranslation();

     useEffect(() => {
        // console.log("use effect appleToken", appleToken);
        handleAppleResponse(appleToken, appleEmail);
      }, [appleToken]); 

    function handleLogin(loginInfo: LoginInfo) {
        const token = Buffer.from(`${loginInfo.login}:${loginInfo.password}`).toString("base64");
        basicAuthentication(loginInfo.login, token).then(
            async () => {
                const basicAuthenticationToken = await getBasicAuthenticationToken(loginInfo.login, loginInfo.password);
                await loginSuccessCommon({authType : AuthDomain.BASIC, user: {login: loginInfo.login}, token: basicAuthenticationToken})
            },
            async (error) => {
                console.log(error);
                setAuthError({domain: AuthDomain.BASIC, errorDescription: getErrorDescription(error.status)});
                await loginFailureCallback();
            }
        )
    }

    function getErrorDescription(status:number) {
        switch(status) {
            case 401:
                return {__html: t('incorrectPassword')};
            case 403:
                return {__html: t('authRoleRequired')};
            default:
                return {__html: t('loginGeneralError')};
          }
        
    } 

    function handleFacebookResponse(response) {
        if (response.accessToken) {
            facebookAuthentication(response.accessToken, response.email).then(
                async () => {
                    await loginSuccessCommon({authType: AuthDomain.FACEBOOK, user: {login: response.email}, token: response.accessToken});
                }, async () => {
                    await loginFailureCallback();
                    setAuthError({domain: AuthDomain.FACEBOOK, errorDescription: {__html:t('facebookLoginError')}});
                });
        }
    }

    async function handleGoogleSuccess(response) {
        if (response.accessToken) {
            googleAuthentication(response.accessToken, response.profileObj.email).then(
                async () => {
                    await loginSuccessCommon({authType: AuthDomain.GOOGLE,user: {login: response.profileObj.email}, token: response.accessToken});
                },
                async () => {
                    await loginFailureCallback();
                    setAuthError({domain: AuthDomain.GOOGLE, errorDescription: {__html:t('googleLoginError')}});
                }
            )
        } else {
            await loginFailureCallback();
            setAuthError({domain: AuthDomain.GOOGLE, errorDescription: {__html:t('googleLoginError')}});
        }
    }

    async function handleGoogleFailure() {
        setAuthError({domain: AuthDomain.GOOGLE, errorDescription: {__html:t('googleLoginError')}});
        await loginFailureCallback()
    }

    async function handleAppleResponse(token: string | undefined, email: string | undefined) {
        if (token && token == "failed") {            
            await loginFailureCallback();
            setAuthError({domain: AuthDomain.APPLE, errorDescription: {__html:t('appleLoginError')}});
        } else if (token && email) {
            let decodedEmail = decodeURIComponent(email);
            appleAuthenticate(token, decodedEmail).then(
                async (response) => {
                    await loginSuccessCommon({authType: AuthDomain.APPLE, user: {login: response.email}, token: token});
                    appHistory.push(PAGE_HOME);
                }, async () => {
                    await loginFailureCallback();
                    setAuthError({domain: AuthDomain.APPLE, errorDescription: {__html:t('appleLoginError')}});
                     
                });
        } 
    } 

    return <Login
        onLogin={handleLogin}
        onGoogleSuccess={handleGoogleSuccess}
        onGoogleFailure={handleGoogleFailure}
        onFacebookResponse={handleFacebookResponse}
        appleRedirectURI= {process.env.PUBLIC_URL + publicServiceUrl + "/getAppleToken"} 
        authError={authError}/>;
};

export default LoginContainer;