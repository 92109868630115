import {ActionsUnion, DispatchAction} from "../type";
import {createAction} from "../action";
import {AuthDomain, User} from "./reducer";

export enum ActionTypes {
    LOGIN_SUCCESS = "loginSuccess",
    LOGIN_FAILURE = "loginFailure",
    LOGOUT = "logout",
    SET_SUBSCRIPTION_STATUS = "setSubscriptionStatus",
}

export type ActionLoginSuccessOptions = {
    authType: AuthDomain;
    token?: string;
    user : User
};

export type ActionSetSubscriptionStatusOptions = {
    subscriptionActive?: boolean
};

export const Actions = {
    loginSuccess: (options: ActionLoginSuccessOptions) => createAction(ActionTypes.LOGIN_SUCCESS, options),
    loginFailure: () => createAction(ActionTypes.LOGIN_FAILURE),
    logout: () => createAction(ActionTypes.LOGOUT),
    setSubscriptionStatus: (options: ActionSetSubscriptionStatusOptions) => createAction(ActionTypes.SET_SUBSCRIPTION_STATUS, options)
};

export type Actions = ActionsUnion<typeof Actions>;

export function loginSuccess(options: ActionLoginSuccessOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.loginSuccess(options));
    };
}

export function loginFailure(): DispatchAction {
    return async dispatch => {
        dispatch(Actions.loginFailure());
    };
}

export function logout(): DispatchAction {
    return async dispatch => {
        dispatch(Actions.logout());
    };
}

export function setSubscriptionStatus(options: ActionSetSubscriptionStatusOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.setSubscriptionStatus(options));
    };
}
