import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
// Custom CSS
import './css/screen3.css';
import './css/myfonts.css';
import './css/video-react.css';
import {StoreProvider} from "./store/provider";

ReactDOM.render(
    <StoreProvider>
        <Suspense fallback={() => {}}>
            <App/>
        </Suspense>
    </StoreProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
