import shaka from 'shaka-player';
import {DomainAndToken} from "./MooveezPlayer";
import {AuthDomain} from "../../../store/reducer-user/reducer";
import {SentenceItem} from "../../../interfaces/MovieInterface";

// configured via build parameters, defaults after || are for for local development
export const MEDIA_PLAYER_PATH = process.env.REACT_APP_MEDIA_PLAYER_PATH || "http://localhost:3000/dict-service-rest/resources/content/video";
export const LICENSE_SERVER_URL = process.env.REACT_APP_LICENSE_SERVER_URL || "http://localhost:8080/license-proxy-modular/getlicense";
export const KSM_SERVER_URL = process.env.REACT_APP_KSM_SERVER_URL || "http://localhost:8080/fair-play-ksm/getKey";

// query string param for chromecast filter to recognize from which application it was called
export const QUERY_PARAM_APP_KEY = "app";
export const QUERY_PARAM_APP_VALUE = "teachers";

export const createAuthenticationRequestFilter = (authentication ?: DomainAndToken) => {
    return function (type, request) {
        if (authentication) {
            const {domain, token} = authentication;
            let xDomain = "";
            switch (domain) {
                case AuthDomain.FACEBOOK:
                    xDomain = "Facebook-Access";
                    break;
                case AuthDomain.GOOGLE:
                    xDomain = "Google-Access";
                    break;
                case AuthDomain.APPLE:
                    xDomain = "Apple-Access";
                    break;
                default:
                    xDomain = "Basic";
            }
            request.headers['Authorization'] = "Bearer " + token;
            request.headers['X-Token-Domain'] = xDomain;
        }

        // Only add headers to license requests:
        if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
            request.withCredentials = true;
        } else {
            request.uris[0] = modifyContentUrlByMediaType(request.uris[0]);
        }
        request.uris[0] = appendApplicationNameQueryString(request.uris[0], QUERY_PARAM_APP_VALUE);
    }
};

function modifyContentUrlByMediaType(uri: any) {
    // TODO DMA maybe other formats are needed for audio files ...
    return (uri.endsWith(".m4a") || uri.endsWith(".aac")) ? uri.replace("video", "audio") : uri;
}

function appendApplicationNameQueryString(uri: any, appName: string) {
    return uri + "?" + QUERY_PARAM_APP_KEY + "=" + appName;
}

/**
 * unwrapping license returned from LicenseServlet
 */
export const createLicenseResponseFilter = () => {
    return function (type, response) {
        if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
            let licenseResponse = shaka.util.StringUtils.fromUTF8(response.data);
            const responseObject = JSON.parse(licenseResponse);
            response.data = shaka.util.Uint8ArrayUtils.fromBase64(responseObject.license);
        }
    }
};

/**
 * Take first sentence startTime and convert to seconds
 * @param sentences
 */
export function getInitialVideoStartTime(sentences?: SentenceItem[]) {
    if (sentences && sentences.length > 0) {
        return (sentences[0].videoPositionStart / 1000);
    }
}

/**
 * Take last sentence andTime and convert to seconds
 * @param sentences
 */
export function getInitialVideoEndTime(sentences?: SentenceItem[]) {
    if (sentences && sentences.length > 0) {
        return (sentences[sentences.length - 1].videoPositionEnd / 1000);
    }
}