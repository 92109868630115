import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

// for localhost testing
//export const DEFAULT_FACEBOOK_APP_ID = '163677980444508';

//My Archimedes app
export const DEFAULT_FACEBOOK_APP_ID ='2249638271994905';

interface FacebookButtonProps {
    onFacebookResponse;
}

const FacebookButton: React.FunctionComponent<FacebookButtonProps> = (props) => {
    const {onFacebookResponse} = props;
    return (
        <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID || DEFAULT_FACEBOOK_APP_ID}
            callback={onFacebookResponse}
            fields="name,email"
            render={renderProps => (
                <button className="loginFacebook" onClick={renderProps.onClick}>Facebook login</button>
            )}
        />
    );
};


export default FacebookButton;
