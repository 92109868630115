import React, {useEffect, useState} from "react"
import i18next from "i18next";
import {useTranslation} from "react-i18next";

interface LanguageInfoProps {
    openAction : Function;
}

const LanguageInfo : React.FunctionComponent<LanguageInfoProps> = (props) => {

    const [selectedLanguage] = useState(i18next.language);
    const { t } = useTranslation();
    const {openAction} = props;

    useEffect(() => {
        i18next.changeLanguage(selectedLanguage);
    }, [selectedLanguage]);

    return (
        <div className="language-info" onClick={() => {openAction(true)}}>
            <span>{t("language")}: {t(i18next.language)}</span>
        </div>
    )
};



export default LanguageInfo;