import React, {useCallback, useEffect, useRef, useState} from "react"
import imgWelcome from "../../../images/logo-mooveez-joy-RGB.png";
import {SentenceItem} from "../../../interfaces/MovieInterface";
import shaka from 'shaka-player';
import {AgGridReact} from "ag-grid-react";
import {AuthDomain} from "../../../store/reducer-user/reducer";
import {
    createAuthenticationRequestFilter,
    createLicenseResponseFilter,
    getInitialVideoEndTime,
    getInitialVideoStartTime,
    LICENSE_SERVER_URL,
    KSM_SERVER_URL,
    MEDIA_PLAYER_PATH
} from "./MooveezPlayerUtils";
import {getMediaPath} from "../../../utils/ResourceBasedUtils";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../../store/type";
import {ErrorType, setError} from "../../../store/reducer-gui-events";
import {useTranslation} from "react-i18next";
import {getFairPlayAppCertificate} from "../../../services/ContentResource";

export interface DomainAndToken {
    domain: AuthDomain | undefined,
    token: string | undefined,
}

interface MooveezPlayerProps {
    sentences?: SentenceItem[],
    videoUrls: {
        mpd: string | undefined,
        m3u?: string | undefined
    },
    authentication: DomainAndToken,
}

let player: shaka.Player;
let timer: shaka.util.Timer;

const MooveezPlayer: React.FunctionComponent<MooveezPlayerProps> = (props) => {

    let {sentences, videoUrls, authentication} = props;
    
    const fullPathMpd  = videoUrls.mpd ?  MEDIA_PLAYER_PATH + videoUrls.mpd : undefined;
    const fullPathM3u  = videoUrls.m3u ?  MEDIA_PLAYER_PATH + videoUrls.m3u : undefined;

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const onColumnResized = (params) => {
        params.api.resetRowHeights();
    };

    const onColumnVisible = (params) => {
        params.api.resetRowHeights();
    };
   
    const {t} = useTranslation();
    const videoRef = useRef(null);
    const gridRef = useRef(null);
    const [authorizationRequestFilter] = useState(() => createAuthenticationRequestFilter(authentication));
    const [licenseResponseFilter] = useState(() => createLicenseResponseFilter());
    const [videoStartTime, setVideoStartTime] = useState(getInitialVideoStartTime(sentences));
    const [videoEndTime, setVideoEndTime] = useState(getInitialVideoEndTime(sentences));
    var fairPlaySupported: boolean | null;
    var fairPlayCert: ArrayBuffer | null;
    const reduxDispatch = useDispatch<Dispatch>();
    const setErrorCallback = useCallback(() => reduxDispatch(setError({
        errorMessage: t("playerError"),
        errorType: ErrorType.FULLSCREEN_ERROR
    })), [reduxDispatch]);

    useEffect(() => {
        setVideoStartTime(getInitialVideoStartTime(sentences));
        setVideoEndTime(getInitialVideoEndTime(sentences));
        // @ts-ignore
        gridRef.current.api.showLoadingOverlay();
        // initialize shaka player
        shaka.polyfill.installAll();
        // Check to see if the browser supports the basic APIs Shaka needs.        
        if (shaka.Player.isBrowserSupported() && videoUrls.m3u) {
            // check Apple HLS + FairPlay DRM supported and get FairPlay certificate
            Promise.all([isFPSSupported(), getFairPlayCert()]).then((values) => {
                let videoUrl = fullPathMpd;
                let isWidevine = true;
                // check Apple HLS videofile avaliable and Fairplay DRM supported
                if (fullPathM3u && values[0]) {
                    videoUrl = fullPathM3u;
                    isWidevine = false;               
                }
                 // Everything looks good! Init video player
                 console.log("initPlayer ", videoUrl); 
                 initPlayer(videoRef.current, videoUrl, values[1], isWidevine);           
              }).catch((e) => {
                setErrorCallback();
                console.log('Error during loading fps cert!', e);
            });
            
        } else {
            // This browser does not have the minimum set of APIs we need.
            setErrorCallback();
            console.error('Browser not supported!');
        }
        // stop timer on component unmount
        return () => {
            timer && timer.stop();
        } 
    }, [sentences]);

    function initPlayer(videoElement, manifestUri, fpsCert, isWidevine) {
        player = new shaka.Player(videoElement);

        timer = new shaka.util.Timer(onTick);

        // handle timer on events
        videoElement.addEventListener('pause', onPauseEvent);
        videoElement.addEventListener('play', onPlayEvent);
        videoElement.addEventListener('seeking', onSeekEvent);

        player.addEventListener('error', setErrorCallback);

        player.configure('drm.advanced.com\\.apple\\.fps\\.1_0.serverCertificate',
                 new Uint8Array(fpsCert));
        player.configure({drm: {servers: 
            {'com.widevine.alpha': LICENSE_SERVER_URL,
            'com.apple.fps.1_0': KSM_SERVER_URL}, }});
        // register request filter, (enrich request of Authorization header)
        player.getNetworkingEngine().registerRequestFilter(authorizationRequestFilter);
        if (isWidevine) {
            // register response filter, (responsible for unwrapping license data from original response)
            player.getNetworkingEngine().registerResponseFilter(licenseResponseFilter);
        }
        player.load(manifestUri, videoStartTime).then(function () {
            // @ts-ignore
            gridRef.current.api.hideOverlay();
            console.log('The video has now been loaded!');
        }).catch((e) => {
            setErrorCallback();
            console.log('Error during loading video!', e);
        });
    }

    async function isFPSSupported() : Promise<boolean | null> {
        if (!fairPlaySupported) {
            let supported = await shaka.Player.probeSupport();
            console.log("shaka.Player.probeSupport()", supported);
            const fpsSupported = supported.drm['com.apple.fps.1_0']; 
            fairPlaySupported = fpsSupported ? true : false;            
        }
        return fairPlaySupported;
    }

    async function getFairPlayCert() : Promise<ArrayBuffer | null>{
        if (!fairPlayCert) {
            const req = await getFairPlayAppCertificate();
            if (req)  {
                fairPlayCert = await req.arrayBuffer();
            } else {
                console.log("Cannot download FPS cert");
                return null;
            }
        }
        return fairPlayCert;
    }

    function onPauseEvent() {
        // @ts-ignore
        timer && timer.stop();
    }

    function onPlayEvent() {
        // @ts-ignore
        timer && timer.tickEvery(0.2);
    }

    function onSeekEvent() {
        if (player) {
            // @ts-ignore
            const currentTime = (player.getMediaElement().currentTime);
            // check if we are still in video clip defined by sentences (if they exists of course)
            if (videoStartTime && (currentTime < videoStartTime)
                || videoEndTime && (currentTime > videoEndTime)) {
                // @ts-ignore
                player.getMediaElement().currentTime = videoStartTime;
                return;
            }
        }
    }

    function onTick() {
        // @ts-ignore
        if (player && player.getMediaElement()) {
            // @ts-ignore
            const currentTime = (player.getMediaElement().currentTime);
            const currentTimeInMs = currentTime * 1000;

            if (videoStartTime && (currentTime < videoStartTime)
                || videoEndTime && (currentTime > videoEndTime)) {
                // @ts-ignore
                player.getMediaElement().currentTime = videoStartTime;
                return;
            }
            // @ts-ignore
            gridRef && gridRef.current && gridRef.current.api.forEachNode(function (node, index) {
                if ((currentTimeInMs) > node.data.videoPositionStart && (currentTimeInMs) < node.data.videoPositionEnd) {
                    node.setSelected(true);
                    // @ts-ignore
                    const playing = (player.getMediaElement().currentTime > 0 && !player.getMediaElement().paused && !player.getMediaElement().ended && player.getMediaElement().readyState > 2);
                    // @ts-ignore
                    playing && gridRef.current.api.ensureIndexVisible(index, 'middle');
                }
            });
        }
    }

    function onGridReady(params) {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }

    function onRowClicked(event) {
        if (player) {
            let selectedRow = getSelectedRow(event);
            if (selectedRow) {
                // @ts-ignore
                player.getMediaElement().currentTime = (selectedRow.data.videoPositionStart / 1000);
                // @ts-ignore
                player.getMediaElement().play();
            }
        }
    }

    function getSelectedRow(event: any) {
        const selectedRows = event.api.getSelectedNodes();
        return (selectedRows && selectedRows.length > 0) ? selectedRows[0] : null;
    }

    interface CellRendererProps {
        value?: string;
    }

    interface OriginalTextRendererProps {
        data: {
            personTalking?: string;
            plainText?: string;
        }
    }

    const ImageCellRenderer: React.FunctionComponent<CellRendererProps> = (props) => {
        const {value} = props;
        return value ? <img src={getMediaPath(value)} width="50px"/> : null;
    };

    const PersonCellRenderer: React.FunctionComponent<OriginalTextRendererProps> = (props) => {
        const {personTalking} = props.data;
        return personTalking ? <strong>{personTalking}:</strong> : null;
    };

    return <>
        <video ref={videoRef}
               width="100%"
               poster={imgWelcome}
               controls autoPlay>
        </video> 
        <div className="ag-theme-material" style={{width: '100%', height: '300px', }}>
            <AgGridReact
                rowHeight={50} 
                onGridReady={(params) => {
                    params.api.setHeaderHeight(0);
                    onGridReady(params);
                }}
                onColumnResized={onColumnResized}
                onColumnVisible={onColumnVisible}
                ref={gridRef}
                defaultColDef={{
                    flex: 1,
                    wrapText: true,
                    autoHeight: true,
                    cellClass: 'fix-line-heights' 
                  }}
                columnDefs={[
                    {
                        colId: "image",
                        headerName: "Image",
                        field: "personImage",
                        cellRendererFramework: ImageCellRenderer,
                        maxWidth: 50 , cellStyle : "padding-top: 0;"
                    }, 
                    {
                        headerName: "Person",
                        field: "personTalking",
                        cellRendererFramework: PersonCellRenderer, 
                        width: 60, maxWidth: 150
                    },
                    {headerName: "spacer1", maxWidth: 15, width: 15 },
                    {headerName: "Original text", field: "plainText"},
                    {headerName: "spacer2", maxWidth: 15, width: 15 },
                    {headerName: "Translated text", field: "translation" }
                    
                ]}
                rowData={sentences}
                rowSelection="single"
                onRowClicked={onRowClicked}
            />
            
        </div>
    </>
};



export default MooveezPlayer