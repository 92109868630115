import React, {useState} from 'react';
import {Field, Form, Formik, FormikActions, FormikProps} from "formik";
import * as Yup from 'yup';
import LabeledInput from "../form/LabeledInput";
import {AuthError, LoginInfo} from "./LoginContainer";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import FacebookButton from "./FacebookButton";
import GoogleButton from "./GoogleButton";
import AppleButton from "./AppleButton";
import {AuthDomain} from "../../../store/reducer-user/reducer";

/**
 * Initial state of Login form
 */
const initialLoginInfo: LoginInfo = {login: "", password: ""};

interface LoginProps {
    authError: AuthError | null;

    onLogin(loginInfo: LoginInfo): void;
    onFacebookResponse;
    onGoogleFailure;
    onGoogleSuccess;
    appleRedirectURI: string;
}

const Login: React.FunctionComponent<LoginProps> = (props) => {

    const {onLogin, authError, onFacebookResponse, onGoogleSuccess, onGoogleFailure, appleRedirectURI} = props;
    const [loginInfo, setLoginInfo] = useState(initialLoginInfo);
    const { t } = useTranslation();

    const checkError = (domain: AuthDomain) => {
        if (authError && authError.domain === domain) {
            return <p className="error-block" dangerouslySetInnerHTML={authError.errorDescription}></p>
        }
    };

    return (
        <div className="form-col-1 log-in">
            <Formik
                initialValues={loginInfo}
                onSubmit={(loginInfo: LoginInfo) => {
                    setLoginInfo(loginInfo);
                    onLogin(loginInfo);
                }}
                validationSchema={Yup.object().shape({
                    login: Yup.string()
                        .min(1, t("loginValidationLength"))
                        .required(t("loginNameMandatory")),
                    password: Yup.string()
                        .required(t("passwordMandatory"))
                })}
                render={() => (
                    <Form>
                        <LabeledInput label={t("username")} name={"login"} type={"text"}
                                      autocomplete={"username"}/>
                        <LabeledInput label={t("password")} name={"password"} type={"password"}
                                      autocomplete={"current-password"}/>
                                      {checkError(AuthDomain.BASIC)}
                        <div>
                            <Field id="submit" type="submit" value={t("login")} className="send-btn"/>
                        </div>
                    </Form>
                )}
            />

            

            <p>
                <Link to="/passwordrestore">{t("forgotPassword")}</Link>
            </p>

            <FacebookButton
                onFacebookResponse={onFacebookResponse}/>

            {checkError(AuthDomain.FACEBOOK)}

            <GoogleButton
                onGoogleSuccess={onGoogleSuccess}
                onGoogleFailure={onGoogleFailure}/>
                
            {checkError(AuthDomain.GOOGLE)}

            <AppleButton
                redirectURI={appleRedirectURI}/>

            {checkError(AuthDomain.APPLE)}

            <p>{t("questions")}<strong><a href="mailto:info@mooveez.com">info@mooveez.com</a></strong></p>

        </div>
    )
};

export default Login;
