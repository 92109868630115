import React from "react";
import Section from "../section/Section";
import imgWelcome from "../../../images/logo-mooveez-joy-RGB.png";
import LoginContainer from "./LoginContainer";
import {useTranslation} from "react-i18next";
import LanguageSwitcher from "../languageswitcher/LanguageSwitcher";
import LanguageInfo from "../languageswitcher/LanguageInfo";
import ReactModal from 'react-modal';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {SignInWithAppleLoginProps} from "./LoginContainer";

const LoginPage: React.FunctionComponent<RouteComponentProps<SignInWithAppleLoginProps>> = (props) => {

    const {t} = useTranslation();
    const {appleToken, appleEmail} = props.match.params;
    let [languageSwitcherVisible, setLanguageSwitcherVisible] = React.useState(false);

    return (
        <>
            <ReactModal
                isOpen={languageSwitcherVisible}
                center
                showCloseIcon={false}
                style={{
                    content: {
                        position: 'undefined',
                        top: 'undefined',
                        left: 'undefined',
                        right: 'undefined',
                        bottom: 'undefined'
                    }
                }}> 
                <LanguageSwitcher closeAction={setLanguageSwitcherVisible} />
            </ReactModal>
            <Section additionalBoxCss="centre" excludeMft>
                <div className="container">
                    <div className="fixed">
                        <img src={imgWelcome}/>
                    </div>
                    <div className="flex-item">
                        <LanguageInfo openAction={setLanguageSwitcherVisible} />
                    </div>
                </div>
                <h1>{t("welcome")}</h1>
                <LoginContainer 
                 appleToken={appleToken}
                 appleEmail= {appleEmail}/>
                <br className="cl"/>
                <p>&nbsp;</p>
            </Section>
        </>
    );
};

export default withRouter(LoginPage);