import React, {useContext, useEffect, useRef, useState} from 'react';
import {MovieListFiltersContext} from "../../../context/MovieListFiltersContext";
import FilterTag, {FilterTagType} from "./FilterTag";
import '../../../localization/i18n';
import {useTranslation} from 'react-i18next';

const SelectedSkillGrammarVocabulary: React.FunctionComponent<{}> = () => {

    let {state, dispatch} = useContext(MovieListFiltersContext);

    const {skillFilter, grammarFilter, vocabularyFilter} = state;
    
    const { t } = useTranslation();

    const removeGrammarFilter = (grammarRule: string) => {
        dispatch({type: "removeGrammarFilter", grammar: grammarRule});
        setExtendedFilter(!state.extendedFilter);
    };
    const removeSkillFilter = (skill: string) => {
        dispatch({type: "removeSkillFilter", skill: skill});
        setExtendedFilter(!state.extendedFilter);
    };
    const removeVocabularyFilter = (vocabulary: string) => {
        dispatch({type: "removeVocabularyFilter", vocabulary: vocabulary});
        setExtendedFilter(!state.extendedFilter);
    };

    const setExtendedFilter = (active) => {
        dispatch({type: "extendedFilter", active: active})
    };


    return (
        <div className="filter-search">
            <ul>
                {skillFilter && <FilterTag tagTitle={skillFilter} onClose={removeSkillFilter} tagType={FilterTagType.SKILLS}/>}
                {grammarFilter && <FilterTag tagTitle={grammarFilter} onClose={removeGrammarFilter} tagType={FilterTagType.GRAMMAR}/>}
                {vocabularyFilter && <FilterTag tagTitle={vocabularyFilter} onClose={removeVocabularyFilter} tagType={FilterTagType.VOCABULARY}/>}
                
            </ul>
        </div>
    )
};

export default SelectedSkillGrammarVocabulary;
