import React from 'react';
import '../../../localization/i18n';
import { useTranslation } from 'react-i18next';
import {formatMillisToMinutes} from "../../../utils/FormattingUtils";

interface MovieStatsProps {
    duration : number;
    wordCount : number;
    includedInLessons : number;
    phraseCount : number;
    exerciseCount : number
}

const MovieStats : React.FunctionComponent<MovieStatsProps> = (props) => {
    const {includedInLessons, exerciseCount, duration, phraseCount, wordCount} = props;
    const { t } = useTranslation();
    return (
        <div className="movie-icons">
            <div className="movie-icon-hours">
                <strong>{formatMillisToMinutes(duration)}</strong><br/>{t('minutes')}
            </div>
            <div className="movie-icon-words">
                <strong>{wordCount}</strong><br/>{t('words')}
            </div>
            <div className="movie-icon-phrases">
                <strong>{phraseCount}</strong><br/>{t('phrases')}
            </div>
            {includedInLessons &&
                <div className="movie-icon-lessons">
                    <strong>{includedInLessons}</strong><br/>{t('lessons')}
                </div>
            }
            <div className="movie-icon-exercises">
                <strong>{exerciseCount}</strong><br/>{t('exercises')}
            </div>
        </div>
    );
};

export default MovieStats;
