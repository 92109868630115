import i18n from 'i18next';
import XHR  from 'i18next-xhr-backend';
import {initReactI18next}  from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next).init({
    debug: true,
    backend : {
        loadPath: (process.env.REACT_LOCALES_BASE || "") + '/locales/{{lng}}/{{ns}}.json'
    },
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    }
});

export default i18n;