import {useSelector} from "react-redux";

import {
    selectFilteredMovieList,
    selectGrammarMap,
    selectLanguages,
    selectMovieList,
    selectSkillMap,
    selectVocabularyMap
} from "./select";

export const useMovieList = () => useSelector(selectMovieList);

export const useFilteredMovieList = () => useSelector(selectFilteredMovieList);

export const useSkillMap = () => useSelector(selectSkillMap);

export const useGrammarMap = () => useSelector(selectGrammarMap);

export const useVocabularyMap = () => useSelector(selectVocabularyMap);

export const useLanguages = () => useSelector(selectLanguages);