import { createSelector } from "reselect";

import { State } from "../type";

import { ReducerActivityTypesState} from "./reducer";

export function selectReducerActivityTypeState(state: State): ReducerActivityTypesState {
    return state.reducerActivityTypes;
}

export const selectActivityTypes = createSelector(
    selectReducerActivityTypeState,
    state => state
);
