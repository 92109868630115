import {Actions} from "../type";
import {ActionTypes, ErrorType} from "./action";

export type ReducerGuiEventsState = {
    errorType: ErrorType | null,
    errorMessage: string | null
}

const initialState: Readonly<ReducerGuiEventsState> = {
    errorType: null,
    errorMessage: null
};

export function reducer(state: ReducerGuiEventsState = initialState, action: Actions): ReducerGuiEventsState {
    switch (action.type) {
        case ActionTypes.SET_ERROR: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case ActionTypes.CLEAR_ERROR: {
            return {
                ...initialState,
            };
        }
        default: {
            return state;
        }
    }
}