import {Actions} from "../type";
import {ActionTypes} from "./action";
//import {ActivityTypeContext} from "../../context/ActivityTypeContext";

export enum AuthDomain {
    BASIC = "basic",
    FACEBOOK = "facebook-access",
    GOOGLE = "google-access",
    APPLE = "apple-access"
}

export interface User {
    login: string,
}

export type ReducerUserState = {
    user?: User;
    token?: string;
    authType?: AuthDomain;
    authenticated?: boolean;
    lastAuthError?: boolean;
    subscriptionActive?: boolean
}

const initialState: Readonly<ReducerUserState> = {
    authenticated: false,
    lastAuthError: false,
    subscriptionActive: false
};

export function reducer(state: ReducerUserState = initialState, action: Actions): ReducerUserState {
    switch (action.type) {
        case ActionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                authenticated: true,
                lastAuthError: false
            };
        }
        case ActionTypes.LOGIN_FAILURE: {
            return {
                user: undefined,
                token: undefined,
                authType: undefined,
                authenticated: false,
                lastAuthError: true
            };
        }
        case ActionTypes.LOGOUT: {
            return {
                user: undefined,
                token: undefined,
                authType: undefined,
                authenticated: false,
                lastAuthError: false
            };
        }
        case ActionTypes.SET_SUBSCRIPTION_STATUS: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default: {
            return state;
        }
    }
}