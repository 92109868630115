import * as React from "react";
import {Dispatch} from "react";
import {Difficulty, LanguageVariant, OrderBy, OrderByDirection} from "../interfaces/MovieInterface";
import {Logger} from "../utils/Logger";

export interface MovieListFiltersContextState {
    difficultyFilter?: Difficulty;
    languageFilter?: LanguageVariant;
    // Linguistic phenomena page keeps its languageFilter state, because it doesn't allows all option
    languageLingusticFilter?: LanguageVariant;
    searchFilter?: string;
    extendedFilter: boolean;
    skillFilter?: string;
    grammarFilter?: string;
    vocabularyFilter?: string;
    orderBy: OrderBy[];
    page?: string;
}

export type MovieListContextAction =
    | { type: 'setSearchFilter', searchPattern: string }
    | { type: 'extendedFilter', active: boolean}
    | { type: 'removeDifficultyFilter'}
    | { type: 'addDifficultyFilter', difficulty: Difficulty }
    | { type: 'addLanguageFilter', language: LanguageVariant }
    | { type: 'removeLanguageFilter' }
    | { type: 'addLanguageLingusticFilter', language: LanguageVariant }
    | { type: 'removeLanguageLingusticFilter' }

    // extended filters (layover)
    | { type: 'addSkillFilter', skill: string }
    | { type: 'removeSkillFilter', skill: string }
    | { type: 'addGrammarFilter', grammar: string }
    | { type: 'removeGrammarFilter', grammar: string }
    | { type: 'addVocabularyFilter', vocabulary: string }
    | { type: 'removeVocabularyFilter', vocabulary: string }

    | { type: 'setOrderBy', orderBy: OrderBy }
    | { type: 'setPage', page: string }

export interface MovieListFiltersContextProps {
    state: MovieListFiltersContextState;
    dispatch: Dispatch<MovieListContextAction>;
}

let MovieListFiltersContext = React.createContext({} as MovieListFiltersContextProps);

export const initialState: MovieListFiltersContextState = {
    extendedFilter : false,
    orderBy: [
        {attribute: "name", direction: OrderByDirection.ASC},
        {attribute: "published", direction: OrderByDirection.ASC}
    ]
};



export const reducer: React.Reducer<MovieListFiltersContextState, MovieListContextAction> = (state, action) => {
    Logger.debug("MovieListContext before", action, {initialState: state});

    let newState = initialState;

    switch (action.type) {
        case "setSearchFilter": {
            newState = {
                ...state,
                searchFilter: action.searchPattern
            };
            break;
        }
        case "extendedFilter": {
            newState = {
                ...state,
                extendedFilter: action.active
            };
            break;
        }
        case "addDifficultyFilter": {
            newState = {
                ...state,
                difficultyFilter: action.difficulty
            };
            break;
        }
        case "removeDifficultyFilter": {
            newState = {
                ...state,
                difficultyFilter: undefined
            };
            break;
        }
        case "addLanguageFilter": {
            newState = {
                ...state,
                languageFilter: action.language
            };
            break;
        }
        case "removeLanguageFilter": {
            return {
                ...state,
                languageFilter: undefined
            };
        }
        case "addLanguageLingusticFilter": {
            newState = {
                ...state,
                languageLingusticFilter: action.language
            };
            break;
        }
        case "removeLanguageLingusticFilter": {
            return {
                ...state,
                languageLingusticFilter: undefined
            };
        }
        case "addSkillFilter": {
            newState = {
                ...state,
                skillFilter: action.skill,
                grammarFilter: undefined,
                vocabularyFilter: undefined
            };
            break;
        }
        case "removeSkillFilter": {
            newState = {
                ...state,
                skillFilter: undefined
            };
            break;
        }
        case "addGrammarFilter": {
            newState = {
                ...state,
                grammarFilter: action.grammar,
                vocabularyFilter: undefined,
                skillFilter: undefined
            };
            break;
        }
        case "removeGrammarFilter": {
            newState = {
                ...state,
                grammarFilter: undefined
            };
            break;
        }
        case "addVocabularyFilter": {
            newState = {
                ...state,
                vocabularyFilter: action.vocabulary,
                grammarFilter: undefined,
                skillFilter: undefined

            };
            break;
        }
        case "removeVocabularyFilter": {
            newState = {
                ...state,
                vocabularyFilter: undefined
            };
            break;
        }
        
    case "setOrderBy": {
            let orderByCloned = state.orderBy.map(orderBy => {
                    if (orderBy.attribute === action.orderBy.attribute) {
                        return action.orderBy
                    } else {
                        return orderBy;
                    }
                }
            );
            newState = {
                ...state,
                orderBy: orderByCloned
            };
            break;
        }
    case "setPage": {
            newState = {
                ...state,
                page: action.page
            };
            break;
        }
    }
    Logger.debug("MovieListContext after", action, {newState: state});
    return newState;
};

export interface ContextProps {
    children?: any
}

function MovieListFiltersContextProvider(props: ContextProps) {
    let [state, dispatch] = React.useReducer<React.Reducer<MovieListFiltersContextState, MovieListContextAction>>(reducer, initialState);
    let value = {state, dispatch};
    return (
        <MovieListFiltersContext.Provider value={value}>{props.children}</MovieListFiltersContext.Provider>
    );
}

export {MovieListFiltersContext, MovieListFiltersContextProvider};