import React, {useCallback} from 'react';
import {getMovies} from "../../services/ContentResource";
import Fetcher from "../../hooks/Fetcher";
import MovieListPage from "./MovieListPage";
import {LanguageVariant, Movie} from "../../interfaces/MovieInterface";
import {getSkillsGrammarsVocabularyForMovie} from "./detail/MovieDetail";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../store/type";
import {setMovies, useMovieList} from "../../store/reducer-movie-list";
import * as _ from "lodash";


/**
 * Fetches movies from backend if they are not provided by {@link MovieListFiltersContextProvider}.
 * Otherwise, use provided movies.
 * Container display
 */
const MovieListPageContainer: React.FunctionComponent<{}> = () => {
    const movies = useMovieList();
    const reduxDispatch = useDispatch<Dispatch>();
    const setLoadedMovies = useCallback((movies) => reduxDispatch(setMovies({
        movies: movies,
        languages: getAllLanguages(movies)
    })), [reduxDispatch]);

    return (
        <Fetcher
            action={!moviesLoaded(movies) ? getMovies : () => movies}
            postLoadAction={(movies) => setLoadedMovies(movies)}>
            {
                () => <MovieListPage movies={movies}/>
            }
        </Fetcher>
    );
};

/**
 * Collect all (unique) filter values for in all lessons in all movies.
 * Right now, three distinct types of filters are available : LanguageSkills (LS), GrammarRules(GR) and Vocabulary VO)
 * As this might take some time in case of thousands of movies, it is advisable to store result in some sort of cache until fresh new movieList is retrieved from backend.
 *
 * @param movies - movies, which should be searched for filter items (LS, GR, VO)
 */
export function getSkillsGrammarsVocabulary(movies: Movie[]): { skillMap: Map<string, string[]>, grammarMap: Map<string, string[]>, vocabularyMap: Map<string, string[]> } {
    let skillMap = new Map<string, string[]>();
    let grammarMap = new Map<string, string[]>();
    let vocabularyMap = new Map<string, string[]>();

    if (movies) {
        for (let movie of movies) {
            movie.lessons && getSkillsGrammarsVocabularyForMovie(movie.lessons, skillMap, grammarMap, vocabularyMap);
        }
    }

    // @ts-ignore
    skillMap = new Map([...skillMap].sort());
    // @ts-ignore
    grammarMap = new Map([...grammarMap].sort());
    // @ts-ignore
    vocabularyMap = new Map([...vocabularyMap].sort());

    return {skillMap, grammarMap, vocabularyMap};
}

/**
 * Selects distinct set of language variants based on languageCode.
 * @param movies
 */
export function getAllLanguages(movies: Movie[]): LanguageVariant[] {
    const languageVariants = movies.map(function (movie) {
        return movie.languageVariant
    });
    return _.uniqBy(languageVariants, "languageCode");
}

export function moviesLoaded(movies: Movie[]): boolean {
    return movies.length > 0;
}

export default MovieListPageContainer;
