import React, {useContext} from 'react';
import {MovieListFiltersContext} from "../../../context/MovieListFiltersContext";
import {Difficulty} from "../../../interfaces/MovieInterface";
import DifficultyFilterItem from "./DifficultyFilterItem";
import '../../../localization/i18n';
import { useTranslation } from 'react-i18next';

const DifficultyFilter: React.FunctionComponent<{}> = () => {
    let {state, dispatch} = useContext(MovieListFiltersContext);

    const addDifficultyToFilter = (difficulty : Difficulty) => dispatch({ type: 'addDifficultyFilter', difficulty: difficulty});
    const removeDifficultyFromFilter = () => dispatch({ type: 'removeDifficultyFilter'});
    const { t } = useTranslation();

    function isActive(difficulty : Difficulty) : boolean {
        if (state.difficultyFilter && state.difficultyFilter.length > 0) {
            return state.difficultyFilter.includes(difficulty);
        } else {
            return false;
        }
    }

    return (
        <div>
            <div className="filter-level">
                <ul>
                    <li >
                        <button className={state.difficultyFilter ? "button-link" : "active button-link"} onClick={() => removeDifficultyFromFilter()}>
                            {t('all')}
                        </button>
                    </li> 
                    <DifficultyFilterItem difficulty={Difficulty.A} active={isActive(Difficulty.A)} onSelect={addDifficultyToFilter} onDeselect={removeDifficultyFromFilter}/>
                    <DifficultyFilterItem difficulty={Difficulty.B} active={isActive(Difficulty.B)} onSelect={addDifficultyToFilter} onDeselect={removeDifficultyFromFilter}/>
                    <DifficultyFilterItem difficulty={Difficulty.C} active={isActive(Difficulty.C)} onSelect={addDifficultyToFilter} onDeselect={removeDifficultyFromFilter}/>
                </ul>
            </div>
        </div>
    );
};


export default DifficultyFilter;
