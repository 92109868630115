import React from 'react';
import AppleLogin from 'react-apple-login'

//My Archimedes app
// for localhost testing
export const DEFAULT_APPLE_APP_ID ='com.mooveez.teacher';

interface AppleButtonProps {
    redirectURI:string;
}

const AppleButton: React.FunctionComponent<AppleButtonProps> = (props) => {
    const {redirectURI} = props;
    return (
        <AppleLogin
            clientId={process.env.REACT_APP_APPLE_APP_ID || DEFAULT_APPLE_APP_ID} 
            responseType="code id_token"
            redirectURI={redirectURI}
            responseMode="form_post"
            render={renderProps => (
                <button className="loginApple" onClick={renderProps.onClick}>Sign in with Apple</button>
            )}
        />
    );
};


export default AppleButton;
