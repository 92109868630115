import React from 'react';
import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom"

export type HomeItemProps = { 
    heading: string;
    boxCss: string;
    iconCss: string;
    text: string;
    linkText: string;
    url: string;
 };

const HomeItem: React.FunctionComponent<HomeItemProps> = (props) =>  {
    
    const { t } = useTranslation();
    const {heading, boxCss, iconCss, text, url, linkText} = props;
    return (
        <div className={boxCss}>
                <div className="box-header"><span className={iconCss}></span></div>
                <div>
                <h2>{heading}</h2>             
                <p dangerouslySetInnerHTML={{__html: text}}></p>
                <Link to={url}>
                    {linkText}
                </Link>
            </div>
        </div>
    );
};

export default HomeItem;