import React from 'react';
import Section from "../common/section/Section";
import {Link} from "react-router-dom"
import {PAGE_LESSON_CONFIGURATOR, PAGE_MOVIES_LIST} from "../common/navigation/Navigation";
import VideoItem from './VideoItem';
import DocItem from './DocItem';
import FaqItem from './FaqItem';
import {useTranslation} from "react-i18next";

const LearningCenterPage: React.FunctionComponent<{}> = () =>  {

    const { t } = useTranslation();

    return (
        <>
        <section className ="title-block" >
                <div className="box">
            <h1><span className="icon-box"></span>{t("learningCenter")}</h1>
        </div>
        </section>
        <section className="info-blocks light mft">    
            <div className="box">
                <div className="box-container">
                    {/* <div className="col-3">
                        <div className="bubble-main">
                            <h2><a href="#video">{t("videoHelp")}</a></h2>
                        </div>
                    </div>                
                    <div className="col-3">
                        <div className="bubble-main">
                        <h2><a href="#docs">{t("documents")}</a></h2>
                        </div>
                    </div> */}
                    <div className="col-3">
                        <div className="bubble-main">
                        <h2><a href="#faq">{t("faq.header")}</a></h2>
                        </div>
                    </div>
                <br className="cl"/>
            </div>
        </div>
        </section>
        {/* <section className="light mft">
           <div className="box" id="video">
             <h2  className="top-line">{t("videoHelp")}</h2>
             <div  className="article-box">        
                <VideoItem heading="" videoSrc="" text=""/>
                <VideoItem heading="" videoSrc="" text=""/>
                <VideoItem heading="" videoSrc="" text=""/>
                <VideoItem heading="" videoSrc="" text=""/>
                <br className="cl"/>
            </div>
           </div>
        </section>
        <section className="light mft">
           <div className="box" id="docs">
             <h2  className="top-line">{t("documents")}</h2>
                <DocItem type="PPT" heading="" docUrl="" text=""/>
                <DocItem type="PPT" heading="" docUrl="" text=""/>
                <DocItem type="PPT" heading="" docUrl="" text=""/>
                <DocItem type="PSD" heading="" docUrl="" text=""/>
                <DocItem type="JPG" heading="" docUrl="" text=""/>
                <DocItem type="DOC" heading="" docUrl="" text=""/>
                <br className="cl"/>
           </div>
        </section> */}
        <section className="light mft">
           <div className="box" id="faq">
             <h2  className="top-line">{t("faq.header")}</h2>
                {getFaq () }
                <br className="cl"/>
           </div> 
        </section>
        </>
    );

    function getFaq () {
        let output=[];
        for (let i = 1; i < 8; i++) {
            let faqItem =  (
                <FaqItem q={t("faq." + i + ".q")} a={t("faq." + i + ".a")} />
           );
            output.push(faqItem);
        }
        return output;
    }
};

export default LearningCenterPage;
