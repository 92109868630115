/**
 * Logging utility for dev purposes.
 * Displaying log messages in console will be enabled only if key "logger" exist in localStorage.
 * Value of "logger" local storage item are log levels, which will be displayed in console (separated by ",")
 */

export class Logger {

    static LS_LOGGER_KEY = "logger";
    static LEVELS = ["debug", "info", "warn", "error"];
    static ENABLED = Logger.initContext();

    private static initContext() {
        try {
            let loggerConfiguration = localStorage.getItem(Logger.LS_LOGGER_KEY);
            if (process.env.NODE_ENV === 'production' || !loggerConfiguration) {
                return false;
            } else {
                let logLevels = loggerConfiguration.split(",");
                Logger.LEVELS = logLevels ? logLevels : ["debug", "info", "warn", "error"];
                return true;
            }
        } catch (exception) {
            return false;
        }
    }

    public static debug(message : string, ...args : any[]) {
        Logger.log("debug", message, args);
    }

    public static info(message : string, ...args : any[]) {
        Logger.log("info", message, args);
    }

    public static warn(message : string, ...args : any[]) {
        Logger.log("warn", message, args);
    }

    public static error(message : string, ...args : any[]) {
        Logger.log("error", message, args);
    }

    private static log(logLevel: string, message: string, args: any[]) {

        let color;
        switch (logLevel) {
            case "debug" : {
                color = "green";
                break;
            }
            case "info" : {
                color = "black";
                break;
            }
            case "warn" : {
                color = "orange";
                break;
            }
            case "error" : {
                color = "red";
                break;
            }
            default : {
                color = "black"
            }
        }

        if (Logger.isEnabled(logLevel)) {
            console.log("%c "+logLevel.charAt(0).toUpperCase()+"> %s", "font-weight:bold; color: " + color, message);
            if (args && args.length > 0) {
                for (let i = 0; i < args.length; i++) {
                    const arg = args[i];
                    console.log("%c    %s", "font-style:italic; color: " + color, (typeof arg === 'object') ? JSON.stringify(arg) : arg);
                }
            }
        }
    }

    private static isEnabled(logLevel : string) : boolean {
        return Logger.ENABLED && (Logger.LEVELS.includes(logLevel));
    }

}

