import { createSelector } from "reselect";

import { State } from "../type";

import { ReducerMovieListState} from "./reducer";

export function selectReducerMovieListState(state: State): ReducerMovieListState {
    return state.reducerMovieList;
}

export const selectMovieList = createSelector(
    selectReducerMovieListState,
    state => state.movies
);

export const selectFilteredMovieList = createSelector(
    selectReducerMovieListState,
    state => state.filteredMovies
);

export const selectSkillMap = createSelector(
    selectReducerMovieListState,
    state => state.skillMap
);

export const selectGrammarMap = createSelector(
    selectReducerMovieListState,
    state => state.grammarMap
);

export const selectVocabularyMap = createSelector(
    selectReducerMovieListState,
    state => state.vocabularyMap
);

export const selectLanguages = createSelector(
    selectReducerMovieListState,
    state => state.languages
);

