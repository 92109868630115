import React, {useCallback, useState} from 'react';
import {ActivityContent, LanguageVariant, Lesson, Movie, SentenceItem} from "../../../interfaces/MovieInterface";
import ActivityContentDetail from "./ActivityContentDetail";
import ActivityContentHeader from "./ActivityContentHeader";
import Tag from "./Tag";
import {getMediaPath} from "../../../utils/ResourceBasedUtils";
import {ActionSetPlayerDataOptions, setPlayerData} from "../../../store/reducer-player-data";
import appHistory from "../../../services/history";
import {PAGE_MOVIE_DETAIL_FULL} from "../../common/navigation/Navigation";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../../store/type";
import {useUserInfo} from "../../../store/reducer-user";
import {requestSubs} from "../../../services/ContentResource";
import {ErrorType, setError} from "../../../store/reducer-gui-events";
import {useTranslation} from "react-i18next";
import {ActionSetReloadFlagOptions, setReloadFlag} from "../../../store/reducer-movie-detail";
import * as _ from "lodash";

interface LessonDetailProps {
    movie: Movie;
    lesson: Lesson;
    activityContent: ActivityContent;
    skillFilter?: string;
    grammarFilter?: string;
    vocabularyFilter?: string;
    availableSkills: Map<string, string[]>;
    availableGrammar: Map<string, string[]>;
    availableVocabulary: Map<string, string[]>;
    subscriptionModalShow(): void;

}

const LessonDetail: React.FunctionComponent<LessonDetailProps> = (props) => {

    const reduxDispatch = useDispatch<Dispatch>();
    const {t} = useTranslation();
    const {movie, lesson, activityContent, grammarFilter, vocabularyFilter, skillFilter, subscriptionModalShow} = props;
    const {subscriptionActive} = useUserInfo();
    const setReloadFlagCallback = useCallback((flag: ActionSetReloadFlagOptions) => reduxDispatch(setReloadFlag(flag)), [reduxDispatch]);
    const setPlayerDataCallback = useCallback((playerData: ActionSetPlayerDataOptions) => reduxDispatch(setPlayerData(playerData)), [reduxDispatch]);
    const setErrorCallback = useCallback(() => reduxDispatch(setError({
        errorMessage: t("playerError"),
        errorType: ErrorType.FULLSCREEN_ERROR
    })), [reduxDispatch]);

    async function playMovie() {
        // in case user has active subscription, but movie was not played yet, we need to subscribe movie for the first time
        const {code, purchased, subscribed, storeProductId, purchasedTranslation} = movie;

        if (!shouldPlay()) {
            subscriptionModalShow();
            return;
        }

        if (!purchased && !subscribed && (subscriptionActive && storeProductId)) {
            requestSubs({
                translationLanguage: "cs",
                productId: storeProductId
            }).then(
                () => {
                    /* set reload flag to load movie detail when coming back from player */
                    setReloadFlagCallback({reloadFlag: true});
                },
                () => {
                    return setErrorCallback();
                }
            )
        }
        await setPlayerDataCallback({
            sentences: transformSentences(purchasedTranslation, activityContent.sentences),
            videoUrls: {
                mpd: "/en/" + code + "/dummy/" + (movie.video.mpdFileName),
                m3u: movie.video.m3uFileName ? "/en/" + code + "/dummy/" + (movie.video.m3uFileName) : undefined }
        });
        appHistory.push(PAGE_MOVIE_DETAIL_FULL + "/player");
    }


    function shouldPlay() {
        console.log("shouldDisplayPlayButton", movie, subscriptionActive);
        const {purchased, subscribed, storeProductId} = movie;
        return (purchased || subscribed || (subscriptionActive && storeProductId))
    }

    return (
        <>
        <div className="lesson-detail">

            <div className="player-link-container">
                <img src={getMediaPath(lesson.image + "")} alt="" width="336" height="180"/>
                    <div onClick={playMovie} className="overlay"/>
            </div>
            <div>
                <div className="filter-lesson-list">
                    {activityContent.vocabulary &&
                    <div className="vocabulary col-3 ">
                        <Tag name={activityContent.vocabulary.name} active={vocabularyFilter !== undefined}
                             enabled={true} keepCursor={true}/>
                    </div>
                    }
                    {activityContent.languageSkill &&
                    <div className="skills col-3">
                        <Tag name={activityContent.languageSkill.name} active={skillFilter !== undefined} enabled={true}
                             keepCursor={true}/>
                    </div>
                    }
                    {activityContent.grammarRule &&
                    <div className="grammar col-3">
                        <Tag name={activityContent.grammarRule.name} active={grammarFilter !== undefined} enabled={true}
                             keepCursor={true}/>
                    </div>
                    }
                </div>
                <h3>{lesson.title + " - " + activityContent.title}</h3>
                <p className="whitespace-on description">{lesson.description}</p>
            </div>
            <br className="cl"/>
            <div className="lesson-closeup">
                <div key={activityContent.id} className="lesson-detail-texts skills-color">
                    <ActivityContentHeader
                        lessonId={lesson.id}
                        activityContent={activityContent}
                    />
                    <ActivityContentDetail
                        activityContent={activityContent}/>
                </div>
            </div>
        </div>
        </>
    );
};


function findTranslation(sentence: SentenceItem, translationLanguage?: LanguageVariant): string {
    if (sentence.translations) {
        for (const translation of sentence.translations) {
            if ((translationLanguage && translation.language.id === translationLanguage.id)
                || (!translationLanguage && translation.language.languageCode.toUpperCase() === 'CS'))  {
                return translation.plainText;
            }
        }
    }
    return "";
}

/**
 * Clone sentences and updates :
 * - person talking
 * - person image
 * - translation based on selected language
 * @param sentences
 */
function transformSentences(translationLanguage?: LanguageVariant, sentences?: SentenceItem[]) {

    console.log("translationLanguage", translationLanguage);
    if (!sentences) {
        return;
    }
    const populatedSentences = _.cloneDeep(sentences);
    console.log("populatedSentences", populatedSentences);

    let personTalking;
    let personImage;

    populatedSentences.forEach(function (sentence: SentenceItem) {

        sentence.translation = findTranslation(sentence, translationLanguage);

        if (!sentence.personTalking)  {
            sentence.personTalking = personTalking;
        } else {
            personTalking = sentence.personTalking;
        }
        if (!sentence.personImage) {
            sentence.personImage = personImage;
        } else {
            personImage = sentence.personImage;
        }
    });
    return populatedSentences;
}

export default LessonDetail;
