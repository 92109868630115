import React, {useEffect, useState} from "react"
import i18next from "i18next";
import imgWelcome from "../../../images/welcome.png";
import {useTranslation} from "react-i18next";

interface LanguageSwitcherProps {
    closeAction : Function;
}

const LanguageSwitcher : React.FunctionComponent<LanguageSwitcherProps> = (props) => {

    const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);
    const availableLanguages = new Map<string, string>();
    availableLanguages.set("en", "english");
    availableLanguages.set("cs", "čeština");
    availableLanguages.set("de", "Deutsch");
    availableLanguages.set("ru", "Русский");
    availableLanguages.set("es", "español");
    availableLanguages.set("fr", "français");
    const {closeAction} = props;
    const {t} = useTranslation();

    useEffect(() => {
        i18next.changeLanguage(selectedLanguage);
    }, [selectedLanguage]);

    function setSelectedLanguageAndClose(lang) {
        setSelectedLanguage(lang);
        closeAction(false);
    }    

    return (
        <div className="overlay-box">
            <div className="overlay-head">
            <img src={imgWelcome}/>
                <h2>
                    <strong>{t("welcome")}</strong>
                    <br/>{t("chooseLanguage")} 
                </h2>
                <br className="cl"></br>
            </div>
       
            {Array.from(availableLanguages.entries()).map((value) => {
                return <a onClick={()=>setSelectedLanguageAndClose(value[0])} href="#">{value[1]}</a>;
            })}

            <br className="cl"></br>         
        </div>
    )
};

export default LanguageSwitcher;