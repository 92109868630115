import normalize from 'normalize-text';
/**
 * Compare two strings, ignoring character case, and also ignoring diacritics (e.g. for first=Axxxx, second=á, function returns true)
 * @param first
 * @param second
 */
export function equalsIgnoreCaseAndDiacritics(first?: string, second?: string) : boolean {
    if (typeof first === 'string' && typeof second === 'string') {
        let result= normalize(first).search(new RegExp(normalize(second.trim()), "i"));
        return (result>=0) ? true : false;
    } else {
        return true;
    }
}

/**
 * Compare all values in the array against needle, ignoring character case, and also ignoring diacritics 
 * @param needle 
 * @param haystack 
 */
export function isInStringArray(needle: string, haystack: string[]) {   
    
    for (const value of haystack){
        if (equalsIgnoreCaseAndDiacritics(value, needle)) {
            return true;
        }
    }
    return false;
}

/**
 * Parse full name to First and Last Name
 * @param name
 * @returns {{firstName: string, lastName: string}}
 */
export function parseName(name) {
    let names = {firstName : "", lastName : ""};
    if (name) {
        const nameArr = name.split(/\s+/);
        names.firstName = nameArr.slice(0, -1).join(" ");
        names.lastName = nameArr.pop();
    }
    return names;
}
