import React from "react"
import {useTranslation} from "react-i18next";
import appHistory from "../../../services/history";

interface ErrorSectionProps {
    errorMessage: string | null;
}

const ErrorSection: React.FunctionComponent<ErrorSectionProps> = (props) => {
    const {errorMessage} = props;
    const {t} = useTranslation();
    return (
        <div className="box">
            <button className="button-link big" onClick={() => appHistory.goBack()}>&lt; {t("back")}</button>
            <hr className="cl"/>
            <div className="lesson-detail">
                <div>
                    <h3>{t("errorHeader")}</h3>
                    <p>{errorMessage}</p>
                </div>
                <br className="cl"/>
            </div>
            <br className="cl"/>
        </div>
    )
};

export default ErrorSection;
