import React from 'react';
import {LanguageVariant} from "../../../interfaces/MovieInterface";
import '../../../localization/i18n';
import { useTranslation } from 'react-i18next';

interface LanguageFilterItemProps {
    language?: LanguageVariant
    active: boolean;

    onSelect(language: LanguageVariant | undefined): void;

    onDeselect(language: LanguageVariant | undefined): void;
}

const LanguageFilterItem: React.FunctionComponent<LanguageFilterItemProps> = (props) => {
    const {language, onSelect, onDeselect, active} = props;
    const { t } = useTranslation();
    return (
        <li>
            <button className={ (active ? "active " : "") + (language ? "button-link icon-" + language.languageCode : "button-link")} onClick={() => active ? onDeselect(language) : onSelect(language)}>
                {language ? <><span></span> {t(language.languageCode)}</>: t('all')}
            </button>
        </li>
    );
};

export default LanguageFilterItem;
