import React, {useContext, useEffect, useState} from 'react';
import * as _ from "lodash";
import '../../../localization/i18n';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import imgWelcome from "../../../images/welcome.png";

interface SubscriptionModalProps {
    visible: boolean;
    setVisible(visible: boolean): void;
}

const SubscriptionModal: React.FunctionComponent<SubscriptionModalProps> = (props) => {

   
    const { t } = useTranslation();
    const {visible, setVisible} = props;  

    return (
              
                <ReactModal
                    isOpen={visible}
                    onRequestClose={() => {setVisible(false)}}
                    center
                    style={{
                        content: {
                            position: 'undefined',
                            top: 'undefined',
                            left: 'undefined',
                            right: 'undefined',
                            bottom: 'undefined'
                        },
                        overlay: {zIndex: 1000}
                    }}>
                    <div className="overlay-box">
                        <div className="overlay-head">
                            <img src={imgWelcome} />
                            <h2>
                                <strong>{t("subscriptionRequiredHeader")}</strong>
                                <p>{t("subscriptionRequiredText")}</p>
                            </h2>
                            <br className="cl"></br>
                        </div>
                        <button className="modalCloseButton" onClick={() => setVisible(false)}>{t("close")}</button>
                        <a className="modalButton" href="https://www.mooveez.com/pro-skoly-a-ucitele">{t("subscriptionShowMore")}</a>
                        <br className="cl"/>
                    </div>
                </ReactModal>
                
    );
};


export default SubscriptionModal;