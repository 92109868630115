import React from 'react';
import {Movie} from "../../../interfaces/MovieInterface";
import MoviesGridGroup from "./MoviesGridGroup";

const MoviesGrid: React.FunctionComponent<{ filteredMovies: Map<string, Movie[]> }> = (props) => {
    const moviesByLanguageMap = props.filteredMovies;
    return (
        <>
            {Array.from(moviesByLanguageMap.entries()).map((entry) => {
                return <MoviesGridGroup key={entry[0]} movies={entry[1]} languageCode={entry[0]}/>;
            })}
        </>
    )
};

export default MoviesGrid;

