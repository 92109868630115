import {ActionsUnion, DispatchAction} from "../type";
import {createAction} from "../action";

export enum ActionTypes {
    SET_ERROR = "setError",
    CLEAR_ERROR = "clearError",
}

export enum ErrorType {
    FULLSCREEN_ERROR = "fullscreenError",
}

export type ActionSetErrorOptions = {
    errorType: ErrorType | null;
    errorMessage: string | null;
};

export const Actions = {
    setError: (options: ActionSetErrorOptions) => createAction(ActionTypes.SET_ERROR, options),
    clearError: () => createAction(ActionTypes.CLEAR_ERROR),
};

export type Actions = ActionsUnion<typeof Actions>;

export function setError(options: ActionSetErrorOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.setError(options));
    };
}

export function clearError(): DispatchAction {
    return async dispatch => {
        dispatch(Actions.clearError());
    };
}