import React from "react"
import {NavLink} from "react-router-dom"

export const PAGE_HOME = "/";
export const PAGE_MOVIES_LIST = "/movies-list";
export const PAGE_LANG_FUNCTIONS = "/lang-functions";
export const PAGE_MOVIE_DETAIL = "/movie-detail";
export const PAGE_LEARNING_CENTER = "/learning-centre";
export const PAGE_MOVIE_DETAIL_FULL = PAGE_MOVIES_LIST + PAGE_MOVIE_DETAIL;
export const PAGE_LESSON_CONFIGURATOR = "/lesson-configurator";

const Navigation : React.FunctionComponent<{}> = () => {
    return (
        <nav id="main-navigation">
            <div>
                <ul>
                    <li><NavLink activeClassName='active' to={PAGE_LESSON_CONFIGURATOR}>konfigurátor lekce</NavLink></li>
                    <li><NavLink activeClassName='active' to={PAGE_MOVIES_LIST}>seznam filmů</NavLink></li>
                    <li><NavLink exact={true} activeClassName='active' to={PAGE_HOME}>úvod</NavLink></li>
                    <li><NavLink exact={true} activeClassName='active' to={PAGE_LEARNING_CENTER}>úvod</NavLink></li>
                </ul>
            </div>
        </nav>
    )
};

export default Navigation;