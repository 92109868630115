import React, {useContext} from 'react';
import {MovieListFiltersContext} from "../../../context/MovieListFiltersContext";
import {LanguageVariant} from "../../../interfaces/MovieInterface";
import LanguageFilterItem from "./LanguageFilterItem";
import {useLanguages} from "../../../store/reducer-movie-list";

const LanguageFilter: React.FunctionComponent<{}> = () => {

    let {state, dispatch} = useContext(MovieListFiltersContext);
    const languages = useLanguages();

    const addLanguageToListFilter = (language: LanguageVariant) => {dispatch({type: 'addLanguageFilter',language: language})};
    const removeLanguageFromListFilter = () => dispatch({type: 'removeLanguageFilter'});

    return (
        <div className="filter-langs">
            <ul>
                <LanguageFilterItem active={state.languageFilter === undefined}
                    onSelect={removeLanguageFromListFilter}
                    onDeselect={removeLanguageFromListFilter}/>
                
                {languages && languages.map(language => { return (
                    <LanguageFilterItem key={language.languageVariantCode} language={language}
                        active={(state.languageFilter && state.languageFilter.languageVariantCode === language.languageVariantCode) as boolean}
                        onSelect={addLanguageToListFilter}
                        onDeselect={removeLanguageFromListFilter}/>
                    )
                })
                }
            </ul>
         </div>
    );
};


export default LanguageFilter;
