import {ActivityType, Movie} from "../interfaces/MovieInterface";
import {apiUrl, doFetch} from "./service";
import {Logger} from "../utils/Logger";

// PORTAL REST API URLs
const serviceUrlPortal =  apiUrl + '/dict-service-rest-portal/webresources';
const serviceUrlPortalTeacherContent = serviceUrlPortal + '/teachercontent';
const serviceUrlPortalReport = serviceUrlPortal + '/report';

export function getLessonPlanPath(reportFor, reportType, lessonId) {
    return serviceUrlPortalReport + "/downloadLessonPlan/" + reportFor + "/"+ reportType + "/"+ lessonId;
}

/**
 * Fetch available activity types from REST API. Activity types are e.g. "Dictate" ...
 */
export function getActivityTypes(): Promise<ActivityType[]> {

    return doFetch(serviceUrlPortalTeacherContent + '/getActivityTypes',
        {
            method: 'GET',
        },
        null
    ).then(
        (response) => {
            Logger.debug("Loaded activityTypes", response as ActivityType[]);
            return Promise.resolve(response as ActivityType[]);
        },
        (error) => {
            Logger.error("Loading activityTypes failed", error);
            return Promise.reject('activityTypes loading failed');
        }
    );
}

/**
 * Fetch movies from REST API
 */
export function getMovies(): Promise<Movie[]> {

    return doFetch(serviceUrlPortalTeacherContent + '/getMovies',
        {
            method: 'GET',
        },
        null
    ).then(
        (response) => {
            Logger.debug("Loaded movies", response.movies as Movie[]);
            return Promise.resolve(response.movies as Movie[]);
        },
        (error) => {
            Logger.error("Loading movies failed", error);
            return Promise.reject('Movie loading failed');
        }
    );
}

/**
 * Fetch movie from REST API based on movie's unique ID
 * @param movieId ID of movie to load
 */
export async function getMovieDetail(movieId : string): Promise<Movie> {

    return doFetch(serviceUrlPortalTeacherContent + '/getMovieDetail/' + movieId,
        {
            method: 'GET',
        },
        null
    ).then(
        (movie) => {
            const loadedMovie = movie as Movie; 
            Logger.debug("Loaded movie detail", loadedMovie);
            return Promise.resolve(loadedMovie);
        },
        (error) => {
            Logger.error("Loading movie detail failed", error);
            return Promise.reject('Movie loading failed');
        }
    );
}

export function loadActivityTypeMap(activityTypesLoadedCallback) {
    getActivityTypes().then(
        async (response) => {
            try {
                let ACTIVITY_TYPE_REGISTR = new Map<number, ActivityType>();
                for (let activityType of response) {
                    ACTIVITY_TYPE_REGISTR.set(activityType.id, activityType);
                }
                await activityTypesLoadedCallback({activityTypeMap: ACTIVITY_TYPE_REGISTR});
            } catch (e) {
                console.log("activityTypes loading failed", e);
            }
        });
}


interface StoreProductData {
    productId: string;
    translationLanguage: string;
}

/**
 * Link account with subscribed movie for the first time the movie is played.
 * @param authToken
 * @param reqData
 */
export function requestSubs(storeProductData: StoreProductData) {
    return doFetch(serviceUrlPortalTeacherContent + "/requestSubs",{method: 'POST'}, storeProductData)
        .then( (response) => {
            if (response.status === 'SUCCESS') {
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        }).catch(() => {
            return Promise.reject();
        });
}

/**
 * Get subscription status for logged in user. Can be active/not active
 */
export function getSubscriptionStatus() {
    return doFetch(serviceUrlPortalTeacherContent + "/getSubscriptionStatus",{method: 'GET'}, null)
        .then( (response) => {
            return Promise.resolve(response.active)
        }).catch((error) => {
            console.log("getSubscriptionStatus failed", error);
            return Promise.resolve(false);
        })
}

/**
 * Get Fair Play DRM application public certificate
 */
export function getFairPlayAppCertificate() {
    return doFetch(serviceUrlPortalTeacherContent + "/getAppCert",{method: 'GET'}, null)
        .then( (response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            console.log("Get Fair Play DRM application public certificate failed", error);
            return Promise.resolve(false);
        })
}




