import {Movie} from "../interfaces/MovieInterface";

/**
 * Add string to string[]. Return cloned array containing new element
 * @param array
 * @param stringToAdd
 */

export function addToArray<T>(array: Array<T>, stringToAdd: T): Array<T> {
    let clone = array.slice(0);
    clone.push(stringToAdd);
    return clone;
}

export function removeFromArray<T>(array: Array<T>, stringToRemove: T): Array<T> {
    return array.filter(function(value){
        return value !== stringToRemove;
    });
}

export function emptyArray<T>(array : Array<T> | null) : boolean {
    return (!array || array.length === 0);
}

/**
 * return settings for lodash orderBy method.
 * orderAttributes defines attributes of sorted object (in this case Movie) which should be applied during sorting
 * orderAttributes defines direction (asc, desc) of sorting for given attribute (according to attribute order defined in orderAttributes)
 * @param orderBy
 */
export function getLodashOrderSettings(orderBy) {
    let orderAttributes = orderBy.map(value => {
        return value.attribute
    });
    let orderDirections = new Array();
    orderBy.forEach(value => {
        orderDirections.push(value.direction);
    });
    return {orderAttributes, orderDirections}
}

/**
 * Compares 2 arrays of numbers.
 * If array1 < array2, returns positive int
 * If array1 == array2, returns 0
 * If array1 > array2, returns negative int
 *
 * examples :
 * [1, 2, 3] compare [1, 2] => returns -1
 * [1, 2, 5] compare [1, 2] => returns -1
 * [1] compare [1] => returns 0
 * [2] compare [1] => returns negative int
 * [1, 4, 5] compare [2, 7] => returns positive int
 *
 * @param a1
 * @param b1
 */
export function compareArraysOfNumbers(a1: string[], b1: string[]) {

    let result = 0;

    let first = a1;
    let second = b1;
    let coefficient = -1;

    if(first.length < second.length) {
        first = second;
        second = a1;
        coefficient = 1;
    }

    let i = 0;
    for (let firstValue of first) {
        let firstIntValue;
        let secondIntValue;

        firstIntValue = parseInt(firstValue);
        secondIntValue = parseInt(second[i]);

        if (isNaN(secondIntValue)) {
            result = -1 * coefficient;
            break;
        }

        if (firstIntValue !== secondIntValue) {
            result = (secondIntValue - firstIntValue) * coefficient;
            break;
        }

        i++;
    }
    return result;

}

export function mapToArray(map : Map<string, Movie[]>) : Movie[] {
    let array = new Array();
    map.forEach((value, key) => {
        array.push(...value);
    });
    return array;
}

export function copyMap(m: Map<string, string[]>, map: Map<string, string[]>, valueModifyingCallback?: any) {
    map.clear();
    m.forEach((value, key) => {
        map.set(key, valueModifyingCallback ? valueModifyingCallback(value) : value);

    })
}

export function removeLetters(array: string[]) {
    return array.map(function(item){
        return item.replace(/\D/g,'');
    })
}


