import React from "react"
import {ErrorType, useGuiEvents} from "../../../store/reducer-gui-events";
import ErrorSection from "../error/ErrorSection";
import SearchFilter from "../../movie-list/filter/SearchFilter";

interface SectionProps {
    title ?: string;
    iconCss ?: string;
    children ?: any;
    additionalBoxCss ?: string;
    excludeMft ?: boolean;
    showSearch ?: boolean;
}

const Section : React.FunctionComponent<SectionProps> = (props) => {
    const {title, iconCss, children, additionalBoxCss, excludeMft, showSearch} = props;
    const {errorType, errorMessage} = useGuiEvents();
    return (
        <section className={"light " + (excludeMft ? "" : "mft")}>
            {errorType === ErrorType.FULLSCREEN_ERROR
                ?
                <ErrorSection errorMessage={errorMessage}/>
                :
                <div className={"box " + (additionalBoxCss ? additionalBoxCss : "")}>
                    <div className="title-box">{title && <h1 className="title-box"><span className={iconCss}></span>{title}</h1>}
                    {showSearch && <><SearchFilter/><br className="cl"/></>}
                    </div>
                    {children}
                </div>
            }
        </section>
    )
};

export default Section;
