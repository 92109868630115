import React from 'react';
import {Movie} from "../../../interfaces/MovieInterface";
import MoviesGridItem from "./MoviesGridItem";
import '../../../localization/i18n';
import {useTranslation} from 'react-i18next';

const MoviesGridGroup: React.FunctionComponent<{ movies: Movie[], languageCode: string }> = (props) => {
    const {movies, languageCode} = props;
    const {t} = useTranslation();
    return (
        <>
            <h2 className={"header-lang icon-" + languageCode}><span></span>{t(languageCode + "Films")}</h2>
            <div className="movie-box">
                {movies.map(movie => {
                    return <MoviesGridItem key={movie.id} movie={movie}/>
                })}
            </div>
            <br className="cl"/>
        </>
    )
};

export default MoviesGridGroup;
