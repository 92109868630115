import React from 'react';
import {
    formatArrayWithSeparator,
    formatDifficultyWithExplanation,
    formatFileSize
} from "../../../utils/FormattingUtils";
import {Difficulty, LanguageVariant} from "../../../interfaces/MovieInterface";
import '../../../localization/i18n';
import { useTranslation } from 'react-i18next';

interface MovieParamsProps {
    difficulty : Difficulty;
    videoFileSize : number;
    published : number;
    availableTranslations ?: LanguageVariant[];
}

const MovieParams : React.FunctionComponent<MovieParamsProps> = (props) => {
    const {difficulty, published, videoFileSize, availableTranslations} = props;
    const { t } = useTranslation();
    return (
        <div className="movie-params">
            <p>
            {t('difficulty')}: {formatDifficultyWithExplanation(difficulty, t)}
            </p>
            <p>
            {t('size')}: {formatFileSize(videoFileSize, true)}
            </p>
            <p>
            {t('releaseDate')}: {published}
            </p>
            <p>
            {t('subtitleOpts')}: {formatArrayWithSeparator("languageCode", availableTranslations, ",").toUpperCase()}
            </p>
        </div>
    );
};

export default MovieParams;
