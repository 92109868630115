import Header from "./components/common/header/Header";
import appHistory from './services/history';
import * as React from 'react';
import {useCallback, useEffect} from 'react';
import {Redirect, Route, Router, Switch} from 'react-router-dom';

import {PAGE_HOME, PAGE_MOVIE_DETAIL_FULL, PAGE_MOVIES_LIST, PAGE_LEARNING_CENTER, PAGE_LANG_FUNCTIONS} from "./components/common/navigation/Navigation";
import LoginPage from "./components/common/login/LoginPage";
// import {LangPhenomenaFiltersContextProvider} from "./context/LangPhenomenaFiltersContext";
import {MovieListFiltersContextProvider} from "./context/MovieListFiltersContext";
import MovieListPageContainer from "./components/movie-list/MovieListPageContainer";
import MovieDetailPageContainer from "./components/movie-list/MovieDetailPageContainer";
import LogoutPage from "./components/common/login/LogoutPage";
import PasswordRestorePage from "./components/common/password-restore/PasswordRestorePage";
import {useDispatch} from "react-redux";
import {Dispatch} from "./store/type";
import {ActionLoginSuccessOptions, loginFailure, loginSuccess, useUserInfo} from "./store/reducer-user";
import {withTranslation} from 'react-i18next';
import {tryAuthenticatedResourcePortal} from "./services/SystemResource";
import {Logger} from "./utils/Logger";
import {AuthDomain} from "./store/reducer-user/reducer";
import MooveezCookieConsent from "./components/common/cookie-consent/MooveezCookieConsent";
import MooveezPlayerPage from "./components/common/player/MooveezPlayerPage";
import {loadActivityTypeMap} from "./services/ContentResource";
import {ActionActivitiesLoadedOptions, activityTypesLoaded} from "./store/reducer-activity-type";
import {setMovieDetail} from "./store/reducer-movie-detail";
import {clearError} from "./store/reducer-gui-events";
import HomePage from "./components/home/HomePage";
import LearningCenterPage from "./components/learning-center/LearningCenterPage";
import LinguisticPhenomenaPageContainer from "./components/movie-list/LinguisticPhenomenaPageContainer";

const App: React.FunctionComponent<{}> = () => {

    const [validatingUser, setValidatingUser] = React.useState(true);
    const reduxDispatch = useDispatch<Dispatch>();
    const {authenticated, token, authType} = useUserInfo();

    const loginSuccessCallback = useCallback((userInfo: ActionLoginSuccessOptions) => reduxDispatch(loginSuccess(userInfo)), [reduxDispatch]);
    const loginFailureCallback = useCallback(() => reduxDispatch(loginFailure()), [reduxDispatch]);
    const activityTypesLoadedCallback = useCallback((activityTypes: ActionActivitiesLoadedOptions) => reduxDispatch(activityTypesLoaded(activityTypes)), [reduxDispatch]);
    const setMovieDetailCallback = useCallback((movie) => reduxDispatch(setMovieDetail({movie})), [reduxDispatch]);
    const clearErrorCallback = useCallback(() => reduxDispatch(clearError()), [reduxDispatch]);

    //
    function validateAuthentication() {
/*         function resolveDomain(authType) {
            if (authType.googleId) {
                return AuthDomain.GOOGLE;
            } else if (authType.facebookId) {
                return AuthDomain.FACEBOOK;
            } else {
                return AuthDomain.BASIC;
            }
        } */

        tryAuthenticatedResourcePortal().then(
            async (response) => {
                Logger.debug("validateAuthentication - auth success");
                // clear movie detail
                await setMovieDetailCallback(null);
                // load activity type map
                await loadActivityTypeMap(activityTypesLoadedCallback);
                // save auth data to redux store
                authType &&  await loginSuccessCallback({
                    authType,
                    token,
                    user: {
                        login: response.loginName
                    }
                });
                // for rendering ...
                setValidatingUser(false);
            },
            async () => {
                Logger.debug("validateAuthentication - auth failure");
                await loginFailureCallback();
                setValidatingUser(false);
            }
        );
    }

    // if authenticated status has changed (it can mean that user logged out, logged in, or reloaded page), check if authentication is still valid.
    // also, error should be cleared when user press back button in browser
    useEffect(() => {
        const unlisten = appHistory.listen((location, action) => {
            if (action === "POP") {
                clearErrorCallback();
            }
        });
        validateAuthentication();
        return () => {
            unlisten();
        }
    }, [authenticated]);

    return (
            <Router history={appHistory}>
            <Switch>
                <Route path="/logout" component={LogoutPage}/>
                <Route path="/passwordrestore" component={PasswordRestorePage}/>
                <Route path="/apple-login/:appleToken/:appleEmail" component={LoginPage}/>
                {authenticated
                    ? <>
                        {/* Header with login/logout info and change language feature*/}
                        <Header/>
                        {/* Context provider for movie list / detail filters sharing using React Context API*/} 
                        <MovieListFiltersContextProvider>                     
                            <Switch>
                                {/* default page   */}
                                <Route path={PAGE_HOME} exact component={HomePage}/>
                               {/* movie list */}
                               <Route path={PAGE_MOVIES_LIST} exact component={MovieListPageContainer}/>
                               {/*  language functions list */}
                               <Route path={PAGE_LANG_FUNCTIONS} exact component={LinguisticPhenomenaPageContainer}/> 
                                {/* movie player page*/}
                                <Route path={PAGE_MOVIE_DETAIL_FULL + "/player"} exact component={MooveezPlayerPage}/>
                                {/* movie detail page */}
                                <Route path={PAGE_MOVIE_DETAIL_FULL + "/:movieId"} component={MovieDetailPageContainer}/>
                                {/* If non-existing URL is typed or accessed via link, redirect to home page */}
                                <Route path={PAGE_LEARNING_CENTER} exact component={LearningCenterPage}/>  
                                {/* If non-existing URL is typed or accessed via link, redirect to home page */}
                                <Route render={() => <Redirect to={{pathname: PAGE_HOME}}/>}/>
                           </Switch>
                            </MovieListFiltersContextProvider>  
                    </>
                    : (validatingUser ? null : <LoginPage />)
                }
            </Switch>
            <MooveezCookieConsent/>
        </Router>
    );
};

// @ts-ignore
export default withTranslation()(App);
