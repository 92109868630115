import React from "react"
import MooveezPlayer from "./MooveezPlayer";
import {useTranslation} from "react-i18next";
import Section from "../section/Section";
import {useUserInfo} from "../../../store/reducer-user";
import {usePlayerData} from "../../../store/reducer-player-data";
import appHistory from "../../../services/history";


const MooveezPlayerPage: React.FunctionComponent = () => {

    const {t} = useTranslation();
    const userInfo = useUserInfo();
    const {sentences, videoUrls} = usePlayerData();

    return (
        <Section>
            <button onClick={appHistory.goBack} className="button-link big player">&lt; {t('back')}</button>
            <div className="box player">
                <MooveezPlayer
                   sentences={sentences}
                   videoUrls={videoUrls}
                   authentication={{
                       domain: userInfo.authType,
                       token: userInfo.token
                   }}
                />
            </div>
        </Section>
    )
};

export default MooveezPlayerPage