import React, {useCallback} from 'react';
import {getMovies} from "../../services/ContentResource";
import Fetcher from "../../hooks/Fetcher";
import LinguisticPhenomenaPage from "./LinguisticPhenomenaPage";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../store/type";
import {setMovies, useMovieList} from "../../store/reducer-movie-list";
import * as _ from "lodash";
import {getAllLanguages, moviesLoaded} from "./MovieListPageContainer";

/**
 * Fetches movies from backend if they are not provided by {@link MovieListFiltersContextProvider}.
 * Otherwise, use provided movies.
 * Container display
 */
const LinguisticPhenomenaPageContainer: React.FunctionComponent<{}> = () => {
    const movies = useMovieList();
    const reduxDispatch = useDispatch<Dispatch>();
    const setLoadedMovies = useCallback((movies) => reduxDispatch(setMovies({
        movies: movies,
        languages: getAllLanguages(movies)
    })), [reduxDispatch]);

    return (
        <Fetcher
            action={!moviesLoaded(movies) ? getMovies : () => movies}
            postLoadAction={(movies) => setLoadedMovies(movies)}>
            {
                () => <LinguisticPhenomenaPage movies={movies}/>
            }
        </Fetcher>
    );
};


export default LinguisticPhenomenaPageContainer;
