import React from 'react';

export enum FilterTagType {
    SKILLS = "skills",
    VOCABULARY = "vocabulary",
    GRAMMAR = "grammar"
}

interface FilterTagProps {
    tagTitle : string;
    tagType : FilterTagType;
    onClose(tagTitle : string): void;
}

const FilterTag: React.FunctionComponent<FilterTagProps> = (props) => {
    const {tagTitle, tagType, onClose} = props;
    if (tagTitle) {
        return <li className="filter-phrase">
            <button className={"button-link-detail " + tagType} onClick={() => onClose(tagTitle)}>
                <span className="tag-title">{tagTitle}</span>
            </button>
        </li>
    } else return null;

};

export default FilterTag;
