import React from 'react';
import {SentenceItem} from "../../../interfaces/MovieInterface";
import ActivitySentence from "./ActivitySentence";

interface ActivitySentences {
    sentences?: SentenceItem[];
}

const ActivitySentences : React.FunctionComponent<ActivitySentences> = (props) => {
    const {sentences} = props;
    return (
        <div>
            {sentences && sentences.map((sentenceItem, index) => {
                return (
                    <ActivitySentence key={index} sentence={sentenceItem}/>
                )})
            }
        </div>
    );
};

export default ActivitySentences;
