/**
 * Movie interface describes complete data needed for displaying
 * - movie list
 * - movie detail
 * - filtering movie list by (difficulty, language)
 * - filtering movie detail lessons by LanguageSkills and GrammarRule
 */
export interface Movie {
    // database ID of movie header
    id: number;

    // name of movie
    name: string;

    // movie description
    description: string;

    // link to cover image
    coverImage: string;

    // movie language
    languageVariant: LanguageVariant;

    // other movie translations
    movieTranslations?: LanguageVariant[];

    // level of learning difficulty
    difficulty: Difficulty;

    // image for movie list page
    posterImage: string;

    // movie video technical details
    video: Video;

    // year when movie was published
    published: number;

    // lessons containing this movie
    lessons?: Lesson[];

    // movie statistics - how many distinct words are in the movie
    distinctBaseWordCount: number;

    // movie statistics - how many phrases are in the movie
    phraseCount: number;

    // movie statistics - how many exercises are defined for the movie
    exerciseCount: number;

    // movie statistics - how many lessons are defined for the movie
    lessonCount: number;

    // for ordering purposes. This is defined in CM
    moduleOrder: number;

    // movie language module code
    code: string;

    // true if movie is purchased by the user, false otherwise
    purchased: boolean;

    // true if movie is accessible by subscription
    subscribed: boolean;

    // id to identify product in store
    storeProductId: string;

    purchasedTranslation?: LanguageVariant
}

/**
 * Language variant identifies different languages for entity where it belongs
 */
export interface LanguageVariant {
    // unique identifier of variant
    id: number;

    // language code
    languageCode: string;

    // country code
    countryCode: string;

    // unique language code for REST API
    languageVariantCode: string;
}

/**
 * Level of learning difficulty (A- easiest, C- hardest)
 */
export enum Difficulty {
    A = "A", B = "B", C = "C"
}

/**
 * Movie video file technical details
 */
export interface Video {

    // name of MPD (MpegDash) file for DRM content
    mpdFileName: string;

    // name of m3u (Aple HlS) file for FairPlay  DRM content
    m3uFileName: string;

    // file name of video file including path
    fileName: string;

    // video file duration in millis
    duration: number;

    // Width in pixels.
    width: number;

    // Height in pixels.
    height: number;

    // video file size ib Bytes
    size: number;
}

/**
 * Movie can be defined in several lessons
 */
export interface Lesson {
    // lesson identifier
    id: number;
    // lesson title (e.g. Lesson 1, Demo ...)
    title: string;
    // short lesson description
    description: string;
    // exercises and activities involved in this lesson
    activityContents: ActivityContent[];
    // id of lesson image
    image: number;
}

/**
 * Activity content
 */
export interface ActivityContent {
    // unique ID of activity content
    id: number;
    // comprehension & discussion questions
    questions?: String[];
    title: string;
    grammarRule?: GrammarRule;
    languageSkill?: LanguageSkill;
    vocabulary?: Vocabulary;
    activityTypes: ActivityType[];
    sentences?: SentenceItem[];
    videoPositionStart: number;
    videoPositionEnd: number;
    public: boolean;

}

/**
 * Sentence item
 */
export interface SentenceItem {
    // graphical identification of person (if available), which is speaking text in this sentence - reference to media file without path
    personImage?: string;
    // textual identification of person (if available), which is speaking text in this sentence
    personTalking?: string;
    // spoken text (original)
    plainText: string;
    // language of sentence
    language: LanguageVariant;
    // translation of the spoken text
    translation: string;
    // where this text starts in video (time mark in seconds from beginning of video)
    videoPositionStart: number;
    // where this text ends in video (time mark in seconds from beginning of video)
    videoPositionEnd: number;
    // sentence translations - they will be distinguished by language attribute
    translations?: Array<SentenceItem>;
}

/**
 * Grammar rule involved in lesson - e.g. "past tense", "present tense", ...
 */
export interface GrammarRule {
    name: string
}

/**
 * Language skill involved in lesson - e.g. "Going on date", "Cooking", ...
 */
export interface LanguageSkill {
    variant: LanguageVariant;

    name: string;
}

/**
 * Vocabulary involved in lesson
 */
export interface Vocabulary {
    id: number;
    name: string;
}

/**
 * Type of activity used in the lesson
 */
export interface ActivityType {
    id: number;
    // Title describing activity type
    title: string;
    description: string;
    // Determines order of the tab containing this ActivityType
    typeOrder: number;
}


export interface OrderBy {
    attribute: string;
    direction : OrderByDirection;
}

export enum OrderByDirection {
    ASC = "asc", DESC = "desc"
}


