import React, {createRef, useEffect} from "react"
import logoImg from "../../../images/logo-trsatko.png"
import {Link} from "react-router-dom";
import {useUserInfo} from "../../../store/reducer-user";
import {ReducerUserState} from "../../../store/reducer-user/reducer";
import LanguageSwitcher from "../languageswitcher/LanguageSwitcher";
import LanguageInfo from "../languageswitcher/LanguageInfo";
import {useTranslation} from "react-i18next";
import ReactModal from 'react-modal';
import {PAGE_MOVIES_LIST, PAGE_HOME, PAGE_LANG_FUNCTIONS, PAGE_LEARNING_CENTER} from "../../common/navigation/Navigation";

const Header: React.FunctionComponent<{}> = () => {
    const node = createRef<HTMLDivElement>();
    const { t } = useTranslation();
    let userInfo = useUserInfo();
    let [logoutWidgetVisible, setLogoutWidgetVisible] = React.useState(false);
    let [languageSwitcherVisible, setLanguageSwitcherVisible] = React.useState(false);

    const handleClickOutside = e => {
        if (node.current && node.current.contains(e.target)) {
            // inside click, do nothing
            return;
        }
        // outside click, close widgets
        setLogoutWidgetVisible(false);
    };

    useEffect(() => {
        if (logoutWidgetVisible ) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [logoutWidgetVisible]);

    return (
        <React.Fragment>
            <header>
            <ReactModal
                isOpen={languageSwitcherVisible}
                center
                showCloseIcon={false}
                style={{
                    content: {
                        position: 'undefined',
                        top: 'undefined',
                        left: 'undefined',
                        right: 'undefined',
                        bottom: 'undefined'
                    }
                }}>
                <LanguageSwitcher closeAction={setLanguageSwitcherVisible} />
            </ReactModal>
            <nav>
                <div className="box">
                    <Link to={PAGE_HOME} className="logo">
                        <img src={logoImg} alt="mooveez - enjoy languages"/>
                        <span>mooveez</span>
                    </Link>
                    <ul><li><a href="mailto:info@mooveez.com">{t('help')}</a></li>
                        <li className="login">
                            <span onClick={() => {setLogoutWidgetVisible(!logoutWidgetVisible)}}>
                                {getDisplayName(userInfo)}
                            </span>
                            {logoutWidgetVisible &&
                                <div ref={node}>
                                    <p>
                                        <Link to="/logout">{t('logout')}</Link>
                                    </p>
                                </div>
                            }
                        </li>
                        <li>
                            <LanguageInfo openAction={setLanguageSwitcherVisible} />
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
        <div id="nav-box">
            <nav id="main-navigation" aria-label="Menu">
            <label htmlFor="toggle-mobile-menu" aria-label="Menu">&#9776;</label>
            <input id="toggle-mobile-menu" type="checkbox" />
                <ul id="main-menu">                    
                   {/*  <li><Link to={PAGE_LEARNING_CENTER}>{t('learningCenter')}</Link></li> */}
                    <li><Link to={PAGE_LEARNING_CENTER}>{t('learningCenter')}</Link></li>
                    <li><Link to={PAGE_LANG_FUNCTIONS}>{t('linguisticPhenomenons')}</Link></li>
                    <li><Link to={PAGE_MOVIES_LIST}>{t('movies')}</Link></li>
                    <li><Link to={PAGE_HOME}>{t('home')}</Link></li>
                </ul>
            </nav>
        </div>
    </React.Fragment>
    )
};

function getDisplayName(state : ReducerUserState) : string {
    let displayName = "login";
    const {authenticated, user} = state;
    if (authenticated && user) {
        const {login} = user;
        //const {firstName, lastName, login} = user;
        //displayName = (firstName && lastName) ? (firstName + " " + lastName) : login;
        displayName = login;
    }
    return displayName;

}

export default Header;
