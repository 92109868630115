import appHistory from './history'
import {Logger} from "../utils/Logger";

const defaultFetchInit = {
    method: "GET",
    redirect: "error",
    cache: "no-cache",
    credentials: "same-origin",
    // xhrFields: {
    //     withCredentials: true
    // },
    // crossDomain: true,
    headers: {}
};



export const apiUrl = "";//process.env.REACT_APP_API_URL ?  process.env.REACT_APP_API_URL : "http://localhost:8080";

export const serviceUrlPortal = apiUrl + '/dict-service-rest-portal/webresources';

/**
 * Enhance http request header with Basic auth info
 * If they are not found, this request will not be secured with basic auth
 * @param init
 */
export function enhanceHeadersBasic(init: any, username : string, password : string) {
    init.headers["Authorization"] = "Basic " + Buffer.from(`${username}:${password}`).toString("base64");
}

/**
 Takes object data and encodes all its enumerable properties into a URL query string.
 * @param data data to be encoded
 * @returns {string}
 */
function serializeQueryParams(data: any) {
    let str = new Array<string>();
    for (let k in data) {
        str.push(encodeURIComponent(k) + "=" + encodeURIComponent(data[k]));
    }
    return str.join("&");
}

export function doFetch(url, init, data, stop401redirect ?: boolean) {
    init = {
        ...defaultFetchInit,
        headers: {
            ...defaultFetchInit.headers
        },
        ...init
    };
    if (data) {
        if (init.method === "GET") {
            url = url + "?" + serializeQueryParams(data);
        } else if (init.method === "POST") {
            init.body = JSON.stringify(data);
            init.headers["content-type"] = "application/json;charset=UTF-8";
        }
    }

    Logger.debug("Fetching data", url, init.method, init.headers, init.body, stop401redirect);

    return fetch(url, init).then(
        (response) => {
            if (response.ok || response.status === 400) {
                Logger.debug("Fetching data success");
                if (response.headers.get("content-type") === "application/json") {
                    return response.json();
                } else {
                    return response;
                }
            }
            Logger.debug("Fetching data failed");

            if (response.status === 402 && !stop401redirect) {
                Logger.debug("Redirecting to /logout after (402)");
                appHistory.push("/logout");
            }
            return Promise.reject(response);
        },
        (error) => {
            Logger.debug("Fetching data failed", error);
            return Promise.reject(error);
        });
}
