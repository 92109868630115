import React, {useState} from 'react';
import {ActivityContent, ActivityType, SentenceItem} from "../../../interfaces/MovieInterface";
import ActivitySentences from "./ActivitySentences";
import {Player} from "video-react";
import {formatTimeForVideoPosition} from "../../../utils/FormattingUtils";

interface ActivityTypeProps {
    activityContent: ActivityContent;
    activityType: ActivityType;
    sentences?: SentenceItem[];
}

const ActivityTypeDetail: React.FunctionComponent<ActivityTypeProps> = (props) => {

    const {activityType, sentences, activityContent} = props;
    const {videoPositionStart, videoPositionEnd} = activityContent;
    const [divId] = useState("myId" + Math.random());

    return (
        <div className="activities-texts">
            <div id={divId}>
                <h1>{activityType.title}</h1>
                {(videoPositionStart != 0 && videoPositionEnd != 0) &&
                    <h2>{formatTimeForVideoPosition(videoPositionStart, videoPositionEnd)}</h2>
                }
                <ActivitySentences sentences={sentences}/>
                <div dangerouslySetInnerHTML={{__html: activityType.description}}/>
            </div>
        </div>
    );
};

export default ActivityTypeDetail;
