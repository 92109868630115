import React, {ReactNode} from "react";
import {applyMiddleware, compose, createStore} from "redux";
import {Provider} from "react-redux";
import thunkMiddleware from "redux-thunk";
import {persistReducer, persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import {createBlacklistFilter} from 'redux-persist-transform-filter';

import {reducer} from "./reducer";
import {Store} from "./type";


// we don't want to persist info about authentication
const saveSubsetBlacklistFilter = createBlacklistFilter(
    'reducerUser',
    ['authenticated', 'lastAuthError']
);

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['reducerMovieList', 'reducerActivityTypes', 'reducerMovieDetail', "reducerGuiEvents"],
    transforms: [saveSubsetBlacklistFilter]
};

const persistedReducer = persistReducer(persistConfig, reducer);

const composeEnhancers = process.env.NODE_ENV === "development" ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const store: Store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

type Props = {
    children: ReactNode;
};

export function StoreProvider(props: Props): JSX.Element {
    const {children} = props;
    return <Provider store={store}>
        <PersistGate loading={null}
                     persistor={persistStore(store)}>
            {children}
        </PersistGate>
    </Provider>;
}

