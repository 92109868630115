import React from "react";
import Section from "../section/Section";
import imgWelcome from "../../../images/welcome.png";
import PasswordRestoreContainer from "./PasswordRestoreContainer";
import {useTranslation} from "react-i18next";

const PasswordRestorePage: React.FunctionComponent<{}> = () => {

    const { t } = useTranslation();

    return (
        <Section additionalBoxCss="centre">
            <img src={imgWelcome}/>
            <h1>{t("passwordRestore")}</h1>
            <PasswordRestoreContainer/>
            <br className="cl"/>
            <p>&nbsp;</p>
        </Section>
    );
};

export default PasswordRestorePage;