import React from 'react';
import {SentenceItem} from "../../../interfaces/MovieInterface";

interface ActivitySentence {
    sentence: SentenceItem;
}

const ActivitySentence: React.FunctionComponent<ActivitySentence> = (props) => {
    const {sentence} = props;
    const {plainText, personTalking} = sentence;
    return (
        <div className="activity-sentence">
            {personTalking &&
                <>
                    <strong>
                        {personTalking}&nbsp;
                    </strong>
                    <br/>
                </>
            }
            {plainText}
        </div>
    );
};

export default ActivitySentence;
