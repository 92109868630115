import { createSelector } from "reselect";

import { State } from "../type";

import { ReducerGuiEventsState} from "./reducer";

export function selectReducerGuiEventsState(state: State): ReducerGuiEventsState {
    return state.reducerGuiEvents;
}

export const selectGuiEvents = createSelector(
    selectReducerGuiEventsState,
    state => state
);
